@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

:root {
    --body-bg: #ffffff;
    --box-bg: rgb(255, 255, 255);
    --input-bg: #ffffff;
    --txt-color: #2f2d2f;
    --txt-second-color: #ccc;
    --border-color: #d1d1d1;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.drop-file-input {
    position: relative;
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--input-bg);
}

.drop-file-input input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
    background-color: rgba(255, 255, 255, 0.2);
}

.drop-file-input__label {
    text-align: center;
    color: var(--txt-second-color);
    font-weight: 600;
    padding: 10px;
}

.drop-file-input__label img {
    width: 100px;
}

.drop-file-preview {
    margin-top: 30px;
}

.drop-file-preview p {
    font-weight: 500;
}

.drop-file-preview__title {
    margin-bottom: 20px;
}

.drop-file-preview__item {
    position: relative;
    display: flex;
    margin-bottom: 10px;
    background-color: var(--input-bg);
    padding: 15px;
    border: 1px solid var(--border-color);
}

.drop-file-preview__item img {
    width: 50px;
    margin-right: 20px;
}

.drop-file-preview__item__info {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.drop-file-preview__item__del {
    background-color: var(--box-bg);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: var(--box-shadow);
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.drop-file-preview__item:hover {
    opacity: 1;
}
