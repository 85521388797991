@import '../node_modules/bootstrap/scss/bootstrap';

@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(./assets/fonts/opensans.woff2) format('woff2');
}
@font-face {
    font-family: 'OpenSans-Lite';
    src: local('OpenSans-Lite'), url(./assets/fonts/OpenSans-Light.woff2) format('woff2');
}

:root {
    --bs-body-color: #003149;
    --bs-primary: #003149;
    --bs-primary-rgb: 0, 49, 72;
    --bs-secondary-rgb: 51, 181, 229;
    --bs-danger-rgb: 255, 0, 0;
    --bs-info-rgb: 51, 181, 229;
}

// html, body {
// 	height: 100%;
// }

// html, body, #root, #root>div {
// 	height: 100%
//   }

body {
    font-family: 'Open Sans', sans-serif;
    color: var(--bs-body-color);
}

h1 {
    font-weight: 300;
}

h2 {
    font-weight: 300;
}

h6 {
    font-weight: 700;
}

strong {
    font-weight: 600;
}

a {
    color: var(--Brand-Secondary);
    text-decoration: none;
    &:active,
    &:focus,
    &:hover {
        color: var(--Brand-Secondary);
    }
    &.text-dark {
        color: var(--bs-body-color) !important;
    }
    &.text-underline {
        text-decoration: underline;
    }
}

.sm {
    font-size: 0.875rem;
}

.md {
    font-size: 0.9375rem;
}

.lg {
    font-size: 1.5rem;
}

.btn-primary {
    background-color: var(--Brand-Secondary);
    border-color: var(--Brand-Secondary);
    box-shadow: none;
    &:active,
    &:focus,
    &:hover {
        background-color: var(--Brand-Secondary);
        border-color: var(--Brand-Secondary);
        box-shadow: none;
    }
}

.text-secondary {
    color: var(--Brand-Secondary) !important;
}

.bg-light {
    background-color: #f0f4f8 !important;
}

.badge {
    border-radius: 100px;
    font-weight: 300;
}

.lead {
    font-size: 1.125rem;
}

.wrapper {
    position: relative;
    min-height: 100%;
}

.container-bgs {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.header {
}

.navbar-brand {
    img {
        max-width: 7rem;
    }
}

.max-width {
    max-width: 87.5rem;
    margin: 0 auto;
}

.form-label {
    font-weight: 500;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: none;
    border-color: #ced4da;
    &:focus {
        box-shadow: none;
        border-color: var(--Brand-Secondary);
    }
}

.invalid-feedback {
    color: var(--Brand-Secondary);
}

.input-field {
    position: relative;
    &.hs-icon {
        &.hs-icon-left {
            .form-control {
                padding-left: 3rem;
            }
        }
    }
    .icon {
        position: absolute;
        top: 0.8rem;
        color: #b2c1c8;
        cursor: pointer;
        &.icon-right {
            right: 1rem;
            line-height: 12px;
        }
        &.icon-left {
            left: 1rem;
        }
        width: 0.875em;
    }
}

.form-control {
    border-color: #b2c1c8;
    &:focus {
        box-shadow: none;
    }
    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #829ab1;
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #829ab1;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #829ab1;
    }
}

.form-check-input {
    width: 1.5em;
    height: 1.5em;
    &:checked {
        background-color: var(--Brand-Secondary);
        border-color: var(--Brand-Secondary);
    }
    &:focus {
        box-shadow: none;
    }
}

.form-check-input[type='checkbox'] {
    margin-left: -2.5em;
    border-radius: 0;
}

.scope-checkbox {
    margin-left: 0px !important;
}

.scope-checkbox[type='checkbox']:not(:checked) {
    /* styles */
    border-color: #003149;
}

.form-check {
    padding-left: 2.5em;
}

.form-select {
    color: var(--bs-primary);
    &:focus {
        box-shadow: none;
    }
}

.input-browse-file {
    position: relative;
    input[type='file'] {
        position: absolute;
    }
    .input-group-text {
        background-color: #ffffff;
        color: #829ab1;
        padding: 2rem 0;
        min-height: 9.25rem;
        strong {
            font-weight: 700;
        }
    }
    .icon {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
    }
    small {
        margin-top: 1rem;
        span {
            // display: block;
        }
    }
}

.datepicker-dropdown {
    padding-left: 1rem;
    padding-right: 1rem;
    color: var(--bs-primary);
}

.sidebar {
    width: 15rem;
    transition: all 0.3s ease-in-out;
    transform: translate(0, 0);
    @media (max-width: 991px) {
        position: fixed;
        z-index: 2;
        top: 0;
        bottom: 0;
        transform: translate(-100%, 0);
        width: 0;
        visibility: hidden;
        opacity: 0;
        overflow-x: hidden;
        overflow-y: auto;
        &.show {
            transform: translate(0, 0);
            width: 18.75rem;
            visibility: visible;
            opacity: 1;
        }
    }
    .close-side-menu {
        cursor: pointer;
    }
    &.hide {
        transform: translate(-100%, 0);
        width: 0;
        visibility: hidden;
        opacity: 0;
    }
    ul {
        li {
            a {
                color: #ffffff;
                border-left-width: 3px;
                border-left-style: solid;
                border-left-color: transparent;
                transition: all 0.3s ease-in-out;
                .icon-right {
                    position: relative;
                    transition: all 0.3s ease-in-out;
                }
                &:hover {
                    background-color: #275877;
                    border-left-color: var(--Brand-Secondary);
                }
            }
            &.active {
                a {
                    background-color: #275877;
                    border-left-color: var(--Brand-Secondary);
                }
            }
            .dropdown-menu {
                @media (max-width: 991px) {
                    background-color: var(--bs-primary);
                }
            }
            .container-target {
                .dropdown-menu {
                    position: static !important;
                }
                .dropdown-toggle {
                    &:hover {
                        background-color: transparent;
                        border-color: transparent;
                    }
                }
            }
        }
    }
}

.container-target {
    .dropdown-toggle {
        cursor: pointer;
        &::after {
            display: none;
        }
    }
    .icon-down {
        color: var(--Brand-Secondary);
        font-size: 2rem;
    }
    .text-target-name {
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1;
        @media (max-width: 991px) {
            font-size: 1rem;
        }
    }
    .text-target-sector {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1;
    }
}

.header-dashboard {
    .hidden-logo-navbar-toggler {
        @media (min-width: 992px) {
            width: 0;
            opacity: 0;
            visibility: hidden;
            &.sidemenu-shown {
                width: auto;
                opacity: 1;
                visibility: visible;
                margin-right: 3rem;
            }
        }
        .logo-hidden-sidemenu {
            max-width: 6rem;
            @media (max-width: 400px) {
                max-width: 5rem;
            }
        }
    }
    .navbar-expand-lg .navbar-toggler.navbar-toggler-sidemenu {
        @media (max-width: 400px) {
            padding-left: 0;
        }
        &:focus {
            outline: none;
            box-shadow: none;
        }
        @media (min-width: 992px) {
            display: inline-block;
        }
    }
    .navbar-nav {
        .nav-item {
            margin-left: 1.5rem;
            @media (max-width: 400px) {
                margin-left: 1rem;
            }
            .icon-menu {
                font-size: 1.5rem;
            }
            .img-user-profile {
                width: 2rem;
            }
            a {
                cursor: pointer;
                color: #829ab1;
                &:hover {
                    .icon-menu {
                        color: var(--Brand-Secondary);
                    }
                }
            }
        }
        .top-1 {
            top: 0.25rem;
        }
        .dropdown-toggle::after {
            display: none;
        }
        .bg-danger {
            background-color: var(--Brand-Secondary) !important;
        }
        .dropdown-menu {
            position: absolute;
        }
    }
}

.text-muted {
    color: #829ab1 !important;
}

.content {
    width: calc(100% - 15rem);
    transition: width 0.3s ease-in-out;
    &.full-width {
        width: 100%;
    }
}

.steps {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
        position: relative;
        padding: 0 2rem 1.5rem;
        @media (max-width: 991px) {
            display: flex;
            align-items: center;
            padding: 0 0 2rem;
        }
        .number {
            border-radius: 100px;
            border: 1px solid #829ab1;
            color: #829ab1;
            background-color: #ffffff;
            width: 3rem;
            height: 3rem;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 1;
            @media (max-width: 991px) {
                margin: 0;
            }
        }
        .line {
            position: absolute;
            top: 23%;
            left: 0;
            right: 0;
            height: 1px;
            background-color: #829ab1;
            @media (max-width: 991px) {
                right: auto;
                top: 0;
                bottom: 0;
                height: auto;
                width: 1px;
                left: 6%;
            }
        }
        .line-dotted {
            position: absolute;
            top: 23%;
            left: 0;
            right: 0;
            height: 1px;
            background: white !important;
            border: 1px dashed #829ab1;
            @media (max-width: 991px) {
                right: auto;
                top: 0;
                bottom: 0;
                height: auto;
                width: 1px;
                left: 6%;
            }
        }
        .tick-sign {
            margin: 0 auto;
            border-radius: 100px;
            color: #ffffff;
            display: none;
            width: 3rem;
            height: 3rem;
            position: relative;
            z-index: 1;
            @media (max-width: 991px) {
                margin: 0;
            }
        }
        .pending-sign {
            margin: 0 auto;
            border-radius: 100px;
            color: #ffffff;
            display: none;
            width: 3rem;
            height: 3rem;
            position: relative;
            height: 36px;
            width: 36px;
            z-index: 1;
            img {
                display: block;
                width: 30px;
                height: 30px;
            }
            @media (max-width: 991px) {
                margin: 0;
            }
        }
        .state {
            font-size: 0.75rem;
            font-weight: 600;
            margin-top: 4px;
        }
        .wrap-icon-flag {
            background-color: #ffffff;
            border: 1px solid #829ab1;
            margin: 0 auto;
            border-radius: 100px;
            color: #ffffff;
            display: none;
            width: 3rem;
            height: 3rem;
            position: relative;
            z-index: 1;
            @media (max-width: 991px) {
                margin: 0;
            }
        }
        .text {
            margin-top: 1rem;
            @media (max-width: 991px) {
                margin-top: 0;
                margin-left: 1rem;
            }
        }
        &:first-child {
            .line {
                @media (min-width: 992px) {
                    left: 50%;
                }
            }
        }
        &.up-coming {
            .line {
                @media (min-width: 992px) {
                    right: 50%;
                }
                @media (max-width: 991px) {
                    display: none;
                }
            }
            .line-dotted {
                @media (min-width: 992px) {
                    left: 50%;
                }
            }
            &:last-child {
                .line-dotted {
                    @media (min-width: 992px) {
                        left: 2px;
                        right: 50%;
                    }
                    @media (max-width: 991px) {
                        display: none;
                    }
                }
            }
        }
        &.current {
            .number {
                border-color: #33b5e5;
                color: #33b5e5;
            }
        }
        &.completed {
            .number {
                display: none;
            }
            .tick-sign {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        &.up-coming {
            .text {
                color: #829ab1;
            }
            .wrap-icon-flag {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        &.pending {
            .number {
                display: none;
            }
            .pending-sign {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.box-content {
    border: 1px solid #ced4da;
    border-radius: 2px;
}

.taget-details {
    .target-name {
        font-size: 2rem;
        line-height: 1;
        font-weight: 300;
    }
}

.stats {
    text-align: center;
    .stats-details {
        @media (max-width: 991px) {
            margin-bottom: 20px;
        }
        .value {
            color: #33b5e5;
            font-size: 2.5rem;
            font-weight: 300;
            @media (max-width: 991px) {
                font-size: 2rem;
            }
        }
    }
    .label {
    }
}

.cpy-right {
    font-size: 0.875rem;
    a {
        color: var(--bs-body-color);
        text-decoration: none;
    }
}

.targetlogo {
    height: 130px;
    border: 1px solid #ced4da;
    color: #829ab1;
    @media (max-width: 991px) {
        width: calc(20px + 22vw);
    }
    @media (max-width: 890px) {
        width: calc(20px + 67vw);
    }
}
.targetlogoimg {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    min-height: 100%;
    min-width: 100%;
    padding: 10px;
}

.height-auto {
    height: auto !important;
}
.form-control.commonInput,
.form-select.commonInput {
    color: #000000 !important;
    font-size: 16px !important;
    font-family: 'Open Sans', sans-serif !important;
}

.svg-icons {
    top: 0.6rem !important;
    width: 1.2em !important;
}

.customPSCheck.form-check-input:checked {
    background-color: #003149;
    border-color: #003149;
}

.customCheck.form-check-input:checked {
    background-color: #35b6e5;
    border-color: #35b6e5;
    border-radius: 2px;
}

.webSiteText {
    color: #003149;
}

.react-datepicker div:after,
.react-datepicker div:before {
    left: -18px !important;
}

.react-datepicker {
    border-color: #b2c1c8 !important;
}

.react-datepicker__header {
    text-align: center;
    background-color: #f0f4f8 !important;
    border-bottom: 1px solid #b2c1c8 !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: var(--Brand-Secondary) !important;
}

.DocumentContainer {
    background: white !important;
}

.react-datepicker-wrapper {
    width: auto !important;
}
