.detailBody {
    /* height: 90vh; */
    background-color: white;
    width: 100%;
    padding: 30px;
}

.avatarStyle {
    height: 40px;
    width: 40px;
    border-radius: 100px;
    background-color: rgb(111, 105, 172);

    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.msgTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.44;
    font-family: OpenSans;
    line-height: 1.44;
    margin: 0px;
}
.msgDescription {
    font-family: OpenSans;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: var(--muted);
    line-height: 1.54;
    margin: 0px;
    height: 20px;
}

.msgTime {
    font-size: 16px;
    color: var(--muted);
    margin-bottom: -3px;
}

.threadBody {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--muted);
    cursor: pointer;
    margin-left: 0px;
    margin-right: 0px;
}

.threadBody:hover {
    box-shadow: 0px 1px 4px #b6b6b6;
}

.replyIcon {
    color: var(--muted);
    height: 37px;
    width: 29px;
    margin-left: 3px;
}
.editorBody {
    border-top: 1px solid #cccccc;
    position: relative;
}

.threadsBody {
    height: 50vh;
    overflow-y: auto;
}
.sendButton {
    margin-top: 15px;
}

.messageButton {
    width: 160px;
}

@media only screen and (min-width: 1244px) {
    .threadDetail {
        display: flex;
        align-items: center;
    }
    .msgTitle {
        margin-right: 60px;
    }
}

.everyoneBadge {
    margin-right: 5px;
    border-radius: 6px;
    cursor: pointer;
    background-color: #30c3cd;
    height: 30px;
    padding-top: 8px;
}
.consultantsbadge {
    margin-right: 5px;
    border-radius: 6px;
    cursor: pointer;
    background-color: #ffbb34;
    height: 30px;
    padding-top: 8px;
}
.managementbadge {
    margin-right: 5px;
    border-radius: 6px;
    cursor: pointer;
    background-color: #ac69ac;
    height: 30px;
    padding-top: 8px;
}
.investorsbadge {
    margin-right: 5px;
    border-radius: 6px;
    cursor: pointer;
    background-color: #6f69ac;
    height: 30px;
    padding-top: 8px;
}

.selectedBadge {
    position: relative;
    top: -5px;
    box-shadow: 0px 11px 11px -4px #666666;
}

.optionWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding: 10px;
}
.optionWrapper:hover {
    background-color: #f0f4f8;
}
.name {
    font-size: 14px;
    font-weight: bolder;
    margin: 0;
}

.position {
    font-size: 14px;
    margin: 0;
    color: var(--muted);
    margin-top: -5px;
}

.nameWrapper {
    margin-left: 10px;
}
