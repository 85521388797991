.viewBody {
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 24px;
    height: calc(100vh - 48px);
    background: var(--Neutral-Gray-3);
    overflow: auto;
}
.pageHeading {
    color: var(--Brand-Primary);
    /* H5/Bold */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin: 0px;
}
.subTitle {
    color: var(--Brand-Primary);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-bottom: 24px;
}
.customSelect {
    height: 32px;
    width: 280px;
    font-size: 13px;
    border-bottom: 1px solid var(--Neutral-Gray-6, #989bad);
}

.submitButton {
    padding: 5px 16px;
    background-color: var(--Brand-Secondary);
    border-radius: 4px;
    outline: none;
    border: none;
    color: var(--Neutral-Foreground-On-Brand-Rest, #fff);
    cursor: pointer;
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
}
.softwareBody {
    padding: 16px;
    margin-bottom: 16px;
    background-color: white;
}
.softwareName {
    color: var(--Brand-Primary, #1b2149);
    margin-bottom: 4px;
    /* H5/Regular */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 24px */
}
.softwareOrg {
    color: var(--Brand-Primary, #1b2149);
    margin-bottom: 1px;
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
}
.lastUpdated {
    color: var(--Neutral-Gray-6, #989bad);
    margin-bottom: 11px;
    /* Small/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
}
.linkText {
    color: var(--Brand-Secondary, #8e2de2);
    margin: 0px;
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    cursor: pointer;
}
.viewLabel {
    color: var(--Neutral-Gray-6, #989bad);

    /* Small/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    margin-bottom: 3px;
}
.viewValue {
    color: var(--Brand-Primary, #1b2149);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-bottom: 19px;
    min-height: 16.8px;
}
.viewmoreBody {
    padding-top: 32px;
    margin-top: 32px;
    border-top: 1px solid var(--Neutral-Gray-6, #989bad);
    padding-bottom: 32px;
}
.navLinkItem {
    padding: 16px;
    padding-right: 24px;
    text-align: right;
    border-right: 1px solid var(--Neutral-Gray-6, #989bad);
    cursor: pointer;
    color: var(--Brand-Primary, #1b2149);
    text-align: right;

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
}
.navLinkBody {
    margin-top: 72px;
}
.selectednavItem > div {
    font-weight: 700;
    border-right: 4px solid var(--Brand-Secondary);
    padding-right: 20px;
}
.formBodies {
    height: calc(100vh - 78px);
    padding-top: 24px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
}
.componentTitle {
    color: #000;
    /* H6/Bold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 21.6px */
    margin-bottom: 56px;
    /* margin-top: 88px; */
}
.componentSmallTitle {
    color: var(--Brand-Primary, #1b2149);
    /* Large/Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    margin-bottom: 24px;
    margin-top: 56px;
}

.fieldLabel {
    color: var(--Brand-Primary);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-bottom: 5.5px;
}
.inputField {
    width: 100%;
    border: 1px solid var(--Neutral-Gray-5);
    border-radius: 4px;
    border-bottom: 1px solid var(--Neutral-Gray-6);
    height: 32px;
    padding-left: 10px;
    padding-right: 10px;
}
.inputField:focus-visible {
    border-bottom: 2px solid #5b5fc7;
    outline: none;
}

.textareaField {
    width: 100%;
    border: 1px solid var(--Neutral-Gray-5);
    border-radius: 4px;
    border-bottom: 1px solid var(--Neutral-Gray-6);
    padding-left: 10px;
    padding-right: 10px;
}
.textareaField:focus-visible {
    border-bottom: 2px solid #5b5fc7;
    outline: none;
}
.selectField > select {
    border-color: var(--Neutral-Gray-5);
    border-bottom: 1px solid var(--Neutral-Gray-6) !important;
}
.currencyDiv {
    width: 72px;
    height: 32px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background: var(--Neutral-Gray-5, #c6c8d2);
    color: var(--Neutral-White, #fff);
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -3px;
    z-index: 1;
}
.noSoftwareDiv {
    height: 144px;
    width: 100%;
    background-color: white;
    border-radius: 4px;
    margin-bottom: 16px;
    color: var(--Neutral-Gray-6, #989bad);

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    padding: 16px;
}
.skeltonDiv {
    height: 144px;
    width: 100%;
    border-radius: var(--Medium, 4px);
    background: var(--Neutral-Gray-2, #f6f6f8);
    margin-bottom: 16px;
}
.required {
    color: #c50f1f;
}
.errorMessage {
    font-size: 10px;
    color: var(--RAG-Red);
}
