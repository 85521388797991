@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700&display=swap');

.headerSection {
    padding-top: 20.5px;
    padding-bottom: 20.5px;
    /* border-bottom: 1px solid rgba(130, 154, 177, 0.5); */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.headerTitle {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Open Sans';
    font-weight: bold;
    color: var(--primary);
}

.swotCard {
    width: 100%;

    background-color: white;
    border-radius: 4px;
    margin-top: 16px;
}

.cardHeader {
    height: 36px;
    padding: 6px 16px;
    color: white;
    background-color: var(--primary);
    font-weight: bold;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 31px;
}

.cardBody {
    padding: 10px 16px;
    height: 34vh;
    overflow: auto;
    resize: vertical;
} /* Scroll Setting */
.cardBody::-webkit-scrollbar {
    width: 10px;
}

.cardRow {
    border-bottom: solid 1px rgba(130, 154, 177, 0.2);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ragCircle {
    height: 16px;
    width: 16px;
    border-radius: 100%;
    margin-left: auto;
    background-color: var(--danger);
}

.swotObs {
    font-size: 12px;
    color: var(--primary);
}

.orangeBtnText {
    color: var(--Brand-Secondary);
    font-size: 12px;
    cursor: pointer;
    margin: 0px;
}
