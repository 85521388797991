@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

.headerSection {
    padding-top: 20.5px;
    padding-bottom: 20.5px;
    border-bottom: 1px solid rgba(130, 154, 177, 0.5);
}

.headerTitle {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Open Sans';
    font-weight: bold;
    color: var(--primary);
}

.smallButton {
    height: 24px;
    font-size: 12px;
    padding-top: 2px;
    padding-left: 16px;
    padding-right: 16px;
}

.responseButton {
    /* border-color: var(--Brand-Secondary); */
    outline: none;
    background: white !important;
    color: var(--Brand-Secondary) !important;
    border: 1.5px solid var(--Brand-Secondary) !important;
}

.disabledSaveBtn {
    color: var(--muted) !important;
    border: 1.5px solid var(--muted) !important;
}

.deleteButton {
    /* border-color: var(--Brand-Secondary); */
    outline: none;
    background: white !important;
    color: var(--Brand-Secondary) !important;
    border: 1px solid var(--Brand-Secondary) !important;
}

.questionsLength {
    font-size: 12px;
    color: var(--muted);
    margin-bottom: 0px;
}

.tableHeader {
    background: #e6eaef;
    color: var(--primary);
    font-size: 12px;
    height: 40px;
    position: sticky;
    top: 0px;
    font-weight: 600;
    font-family: 'Open Sans';
    z-index: 1;
}

.tableContainer {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 260px);
}

.tableRow {
    background-color: white;
    height: 60px;
    color: var(--primary);
    font-size: 12px;
    border-bottom: solid 1px rgba(130, 154, 177, 0.2);
    cursor: pointer;
    font-weight: 400;
    font-family: 'Open Sans';
}

.rowCheck {
    display: none;
}

.tableRow:hover .rowCheck {
    display: block;
}

.smallFont {
    font-size: 12px !important;
}

.workstreamSelect {
    width: 100%;
    height: 40px;
    border: 1px solid #ced4da !important;
    font-size: 16px;
    padding-left: 10px;
    border-radius: 4px;
    border: 1px solid var(--muted);
    background-color: white;
    /* width: 175px; */
    min-height: 36px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: var(--primary);
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    user-select: none;
    margin-right: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: var(--muted);
}

.workstreamSelect + div {
    transform: translate3d(0px, 38px, 0px) !important;
    inset: -14px auto auto 0px !important;
}

.filterSelector {
    border-radius: 4px;
    border: 1px solid var(--muted);
    background-color: white;
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: var(--muted);
    cursor: pointer;
    user-select: none;
    width: 120px;
    display: flex;
    justify-content: center;
}

.filterSelector + div {
    transform: translate3d(0px, 38px, 0px) !important;
    inset: -14px auto auto 0px !important;
}

.bulkSelector {
    border-radius: 4px;
    border: 1px solid var(--Brand-Secondary);
    background-color: white;
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: var(--Brand-Secondary);
    cursor: pointer;
    user-select: none;
    width: 120px;
    display: flex;
    justify-content: center;
}

.bulkSelector + div {
    transform: translate3d(0px, 38px, 0px) !important;
    inset: -14px auto auto 0px !important;
}

.filterBody {
    width: 156px;
    background-color: white;
    border-radius: 4px;
    padding: 0px 15px 0px 15px;
}

@media (max-width: 991px) {
    .filterBody {
        width: 100%;
    }
}

.checkLabel {
    font-size: 12px;
}

.ragBadge {
    border-radius: 100px;
    height: 18px;
    width: 18px;
    background-color: var(--Brand-Secondary);
    margin: auto;
}

.tabViewBody {
    background-color: white;
    padding: 11px 18px;
}

.menuButton {
    background-color: white;
    padding: 8px 18px;
    height: 36px;
    width: 96px;
    margin-top: 16px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 12px;
    color: var(--info);
}

.orangeBtnText {
    color: var(--Brand-Secondary);
    font-size: 12px;
    cursor: pointer;
    margin: 0px;
}

.blueText {
    font-size: 12px;
    color: var(--info);
}

.redText {
    color: #ff0000;
    font-size: 12px;
}

.greenText {
    color: #00c851;
    font-size: 12px;
}

.smallBlueLabel {
    color: var(--info);
    font-size: 10px;
}

.regularText {
    font-size: 14px;
}

.richText > p {
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.blueColor {
    color: var(--info);
}

.rowItem {
    vertical-align: top !important;
    padding-top: 12px;
}

.kebabBody > div > div {
    left: -130px;
    top: 27px;
}

.labelAddQuestion {
    font-size: 12px;
}

.completeDiv {
    width: 90px;
    height: 24px;
    padding: 4px 20px;
    border-radius: 4px;
    border: solid 1px #006400;
    background-color: rgba(0, 100, 0, 0.1);
    font-size: 10px;
    color: #006400;
}

.blueLabel {
    color: var(--info);
    margin: 0px;
    font-size: 12px;
    font-family: 'Open Sans';
    text-transform: capitalize;
}

.lightGray {
    color: var(--muted);
}

.dashLine {
    border: dashed 0.7px var(--muted);
    height: 1px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.observationText > p {
    margin: 0;
}

.modalBody {
    padding: 30px 20px 30px 20px;
    text-align: center;
}

.cancelButton {
    background-color: var(--muted);
    margin-right: 30px;
    width: 120px !important;
    color: white;
}

.tagChip {
    background-color: var(--info);
    font-size: 12px;
    padding: 3px 10px;
    border-radius: 20px;
    color: white;
    margin-right: 10px;
    margin-bottom: 10px;
}

.xsmallFont {
    font-size: 10px;
}

.confirmationModalBody {
    width: 480px;
    border: solid 1px var(--danger);
    border-radius: 12px;
    /* display: flex; */
    min-height: 160px;
    padding: 0px;
}

.modalMainBody > div > div {
    border-radius: 12px !important;
    width: auto !important;
    border: none;
}

.redBgWarnModal {
    background-color: var(--danger);
    width: 120px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.confirmationModalMsg {
    padding: 24px;
    flex: 1;
}

.confirmationModalMsg > h3 {
    font-size: 24px;
    font-weight: bold;
}

.confirmationModalMsg > p {
    font-size: 14px;
    font-weight: normal;
}

.orangeLink {
    color: var(--Brand-Secondary);
    cursor: pointer;
    font-size: 12px;
}

.filterTag {
    height: 24px;
    background: var(--muted);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 12px;
    font-size: 12px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    text-transform: capitalize;
    cursor: pointer;
}

.wsDropdown > div {
    top: 0px !important;
}

.disabledWsOption {
    background: #c6c8ca8c;
}

.disabledWsOption:hover .wsDelete {
    display: block;
}

.cosWSOption {
    color: var(--muted);
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
}

.cosWSOption:hover {
    color: var(--primary);
    background-color: #ddd;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.hoverMenuContent {
    display: none;
}

.dropdown:hover .hoverMenuContent {
    display: block;
}

.dropdown:hover .quickAddIcon {
    color: var(--primary);
}

.hoverMenuCont {
    width: 175px;
    position: absolute;
    right: -0px;
    /* min-width: 10rem; */
    padding: 9px;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    z-index: 4;
    overflow-y: auto;
    max-height: 175px;
    border-radius: 4px;
    box-shadow: 1px 2px 4px 0 rgb(130 154 177 / 30%);
    border: solid 1px rgba(130, 154, 177, 0.2);
    font-size: 12px;
}

.hoverMenuCont ul {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
}

.hoverMenuCont ul li a {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    text-align: inherit;
    text-decoration: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    word-wrap: break-word;
    color: #829ab1;
    box-sizing: border-box;
}

.hoverMenuCont ul li a:hover {
    background-color: #e9ecef;
}

.rowItem {
    color: var(--primary);
}

.sidebarTitle {
    color: var(--Brand-Primary);
    /* Large/Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 40px;
    line-height: 120%; /* 19.2px */
}
.quillShowToolbar {
    position: absolute;
    right: 34px;
    top: -5px;
}
.saveButton {
    width: 80px;
    height: 32px;
    background-color: var(--Brand-Secondary);
    border: none;
}
.fieldLabel {
    color: var(--Brand-Primary);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-bottom: 5.5px;
}
.customSelect {
    border: 1px solid var(--Neutral-Gray-5);
    border-bottom: 1px solid var(--Neutral-Gray-6);
    border-radius: 4px;
    height: 32px;
    font-size: 14px;
    padding: 5px;
}
.customReactQuill > div > div:nth-child(2) {
    border: 1px solid var(--Neutral-Gray-5) !important;
    border-bottom: 1px solid var(--Neutral-Gray-6) !important;
    border-radius: 4px;
}
