@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');
.headerSection {
    padding-top: 20.5px;
    padding-bottom: 5.5px;
    border-bottom: 1px solid rgba(130, 154, 177, 0.5);
}
.headerTitle {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Open Sans';
    font-weight: bold;
    color: var(--primary);
}
.selectTag {
    font-size: 12px;
    color: var(--primary);
    margin-top: 11px;
    margin-bottom: 16px;
}
.tagChip {
    border-radius: 18px;
    font-size: 13px;
    color: var(--info);
    padding: 1px 13px;
    border: 1px solid var(--info);
    margin-right: 5px;
    margin-bottom: 8px;
    cursor: pointer;
}
.tableHeader {
    background-color: #e6eaef;
    height: 40px;
    display: flex;
    padding: 13px;
    font-family: OpenSans;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
}

.tableRow {
    background-color: white;
    height: 40px;
    display: flex;
    padding: 13px;
    font-family: OpenSans;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
    border-bottom: solid 1px rgba(130, 154, 177, 0.2);
}
.smallButton {
    font-size: 12px;
    padding: 0px 10px;
}

.tableRow > div {
    min-width: 14px;
}
.rowCheck {
    display: none;
}
.tableRow:hover .rowCheck {
    display: block;
}
.wsTitle {
    font-family: OpenSans;
    font-size: 14px;
    font-weight: 800;
    color: var(--info);
    margin: 0px;
}
.tag {
    font-family: OpenSans;
    font-size: 14px;
    margin-bottom: 40px;
    font-weight: 600;
    color: var(--info);
}
.questionText {
    font-size: 14px;
    color: var(--primary);
}
.responseButton {
    /* border-color: var(--Brand-Secondary); */
    outline: none;
    background: white !important;
    color: var(--Brand-Secondary) !important;
    border: 1.5px solid var(--Brand-Secondary) !important;
}
