@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(./opensans.woff2) format('woff2');
}
@font-face {
    font-family: 'OpenSans-Lite';
    src: local('OpenSans-Lite'), url(./OpenSans-Light.woff2) format('woff');
}
.full-height-cls {
    height: 100vh !important;
}
.dchart-legend {
    display: none !important;
}
.dchart-innertext-value {
    display: none !important;
}

.dchart-arcs-path {
    opacity: 1;
    border: none !important;
    stroke-width: 0px;
}
.dchart {
    position: relative;
    top: -56px;
    left: 35px;
}

.chart-inner-text {
    width: 40px;
    text-align: center;
    position: relative;
    top: -15px;
    left: 42px;
}
.chart-inner-text h4 {
    font-family: 'openSans';
    font-size: 20px;
    font-weight: bold;
    margin: 0px;
}
.chart-inner-text p {
    font-family: 'OpenSans-Lite';
    font-size: 10px;
    margin: 0px;
    margin-top: -3px;
}
.heading-height {
    height: 9vh;
}
.chart-container {
    position: relative;
    top: 1.7vh;
    left: -1vw;
}
