@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

.navbarbrand {
    margin-left: 60px;
    padding-top: 15px;
    margin-bottom: 27px;
}

.pb-2 {
    padding-bottom: 0.5rem !important;
}

.rightContent {
    width: 100%;
    margin-left: 45px;
}

.rightImage {
    width: 270px;
    height: 100vh;
    position: absolute;
    right: 0px;
    top: 0px;
    background: url(/public/assets/images/verifyEmail.png);
    background-size: cover;
    background-repeat: no-repeat;
}
.formBody {
    padding-top: 30px;
}
.headingText {
    font-size: 28px;
    color: var(--primary);
    font-weight: bold;
    font-family: 'Open Sans';
}
.orangeText {
    color: var(--Brand-Secondary);
    cursor: pointer;
}

.copyright {
    color: var(--muted);
    font-size: 10px;
}
.forgotPassDiv {
    margin-top: 42px;
}
.viewHeight {
    min-height: calc(100vh - 80px - 20px);
}

.emailText {
    margin-top: 5px;
    margin-bottom: 20px;
}
.cantGetText {
    margin-top: 35px;
    margin-bottom: 19px;
}
.closeTab {
    font-weight: bold;
    cursor: pointer;
    color: var(--Brand-Secondary);
}

@media screen and (max-width: 990px) {
    .rightImage {
        width: 200px;
    }
}

@media screen and (max-width: 720px) {
    .rightImage {
        display: none;
    }
    .rightContent {
        margin-left: 0px;
    }
}
