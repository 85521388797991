.body {
    background-color: var(--light);
    height: calc(100vh - 48px);
    overflow: auto;
    background: var(--Neutral-Gray-3);
    padding-left: 60px;
    padding-right: 60px;
}
@media (max-width: 700px) {
    .body {
        padding: 12px;
    }
}
.addProjectBtn {
    border-radius: 4px;
    background: var(--Brand-Secondary);
    width: 127px;
    height: 40px;
    border: none;
    color: var(--Neutral-Foreground-On-Brand-Rest, #fff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
}
.archiveBtnText {
    color: var(--Brand-Secondary);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    cursor: pointer;
}
.projectCard {
    height: 320px;
    border-radius: 4px;
    background: var(--Brand-Primary);
    width: 100%;
    padding: 16px;
    position: relative;
}
.chartText {
    color: var(--Neutral-White, #fff);
    text-align: center;
    margin-bottom: 3px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    position: absolute;
    top: 19px;
    left: 8px;
    width: 36px;
}
/* .projectCard:hover .workstreamDetails {
    display: block;
    animation: expandHeight 0.2s ease-in-out forwards;
}
.workstreamDetails {
    position: absolute;
    width: 100%;
    height: 0px;
    background-color: white;
    top: 0px;
    left: 0px;
    border-radius: 4px;
    display: none;
    padding: 16px;
    cursor: pointer;
} */
@keyframes expandHeight {
    from {
        height: 0; /* Start height */
    }
    to {
        height: 320px; /* End height */
    }
}
.projectTitle {
    color: var(--Neutral-White, #fff);

    /* Large/Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 5px;
    line-height: 120%; /* 19.2px */
}
.companyName {
    color: var(--Neutral-White, #fff);

    /* Small/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    margin-bottom: 0px;
}
.wsTitle {
    color: var(--Neutral-Gray-6);

    /* Small/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    margin-bottom: 20px;
}
.wsDetailText {
    color: var(--Brand-Primary, #1b2149);
    margin-bottom: 4px;
    /* Small/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
}

.ragDiv {
    border-top: 1px solid var(--Neutral-Gray-6);
    border-bottom: 1px solid var(--Neutral-Gray-6);
    padding: 8px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    margin-top: 106px;
}
.rag {
    height: 32px;
    width: 32px;
    color: var(--Neutral-White, #fff);
    text-align: center;
    /* Body/Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 16.8px */
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
}

.cardLabel {
    color: var(--Neutral-Gray-6);
    /* XSmall/Regular */
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 13.2px */
    margin: 0px;
}
.boldText {
    color: var(--Neutral-White, #fff);

    /* Body/Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 16.8px */
    text-align: center;
    margin: 0;
}
.maturitySecurity {
    height: 48px;
    width: 48px;
    border: 6px solid;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3px;
    color: var(--Neutral-White, #fff);
    text-align: center;
    margin-bottom: 3px;
    /* Body/Bold */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 16.8px */
}
.lastUpdatedText {
    color: var(--Neutral-Gray-6, #989bad);
    /* Small/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
}
.menuDiv {
    position: absolute;
    position: absolute;
    right: 20px;
    z-index: 1;
    top: 13px;
}
/* .projectCard:hover .workstreamDetails {
    display: block;
    animation: expandHeight 0.2s ease-in-out forwards;
} */
.nonActiveLabel {
    color: #000;
    /* H6/Bold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 21.6px */
    /* margin-bottom: 2s0px; */
    height: 40px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
}
.deletedText {
    color: #000;
    margin-bottom: -16px;
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
}
