@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

.navbarbrand {
    margin-left: 60px;
    padding-top: 15px;
    margin-bottom: 27px;
}
.viewHeight {
    min-height: calc(100vh - 60px);
}
.content {
    padding-left: 97px;
    padding-top: 17px;
}
.formBody {
    padding-top: 30px;
    width: 100%;
    margin-left: 45px;
}

.validUrl {
    font-size: 10px;
    position: relative;
    top: -4px;
    color: var(--danger);
    left: 2px;
}
.body {
    padding-left: 90px;
    padding-right: 90px;
    padding-top: 17px;
    min-height: calc(100vh - 60px);
    overflow: auto;
}
.borderdButton {
    font-size: 12px;
    background-color: white;
    border: 1px solid var(--Brand-Secondary);
    border-radius: 4px;
    outline: none;
    color: var(--Brand-Secondary);
    padding: 2px 20px;
}

.titleBody {
    margin-bottom: 25px;
}
.headingText {
    font-size: 20px;
    color: var(--primary);
    font-weight: bold;
    font-family: 'Open Sans';
}
.uploadDiv {
    border: 1px solid #c0ccd8;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #c0ccd8;
    padding: 20px;
    background-color: white;
    cursor: pointer;
}
@media screen and (max-width: 720px) {
    .formBody {
        margin-left: 0px;
    }
    .content {
        padding-left: 0px;
    }
}
@media screen and (max-width: 980px) {
    .body {
        padding: 0px;
    }
}

.orangeText {
    color: var(--Brand-Secondary);
}

.quillTextArea > div > div:last-child {
    height: 277.5px !important;
    overflow-y: auto;
}

.smallButton {
    height: 24px;
    font-size: 12px;
    padding-top: 2px;
    padding-left: 26px;
    padding-right: 26px;
}
.outlineButton {
    /* border-color: var(--Brand-Secondary); */
    outline: none;
    background: white !important;
    color: var(--Brand-Secondary) !important;
    border: 1.5px solid var(--Brand-Secondary) !important;
}
.revenueField {
    background-color: white;
    border-radius: 4px;
    border: solid 1px rgba(130, 154, 177, 0.5);
    height: 36px;
}
.borderlessField {
    border: none;
    outline: none;
}

.menuItem {
    font-size: 16px;
    color: var(--Brand-Secondary);
    cursor: pointer;
}
