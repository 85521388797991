.viewBody {
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 24px;
    height: calc(100vh - 48px);
    background: var(--Neutral-Gray-3);
    overflow: auto;
}
.pageHeading {
    color: var(--Brand-Primary);
    /* H5/Bold */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin: 0px;
}
.subTitle {
    color: var(--Brand-Primary);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-bottom: 24px;
}
.headerSimpleButton {
    font-family: Inter;
    border-radius: 4px;
    border: 1px solid var(--Brand-Secondary);
    color: var(--Brand-Secondary);
    background: var(--Neutral-Background-Transparent-Rest);
    width: 200px;
    font-size: 14px;
    margin-right: 24px;
    height: 32px;
    margin-bottom: 32px;
}
.selectedBtn {
    background: var(--Brand-Secondary);
    color: white;
}
.questionBody {
    /* height: 64px; */
    width: 100%;
    border-radius: 4px;
    background-color: white;
    /* padding: 21px 32px; */
    margin-bottom: 8px;
}
.categoryName {
    color: var(--Brand-Primary);
    /* Large/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    margin-left: 13px;
}
.ragCircle {
    height: 23px;
    width: 23px;
    border: 1px solid var(--Neutral-Gray-4);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
}
.ragCircleInside {
    height: 15px;
    width: 15px;
    background-color: var(--Neutral-Gray-4);
    border-radius: 100px;
}
.expandedCategory {
    /* border-top: 1px solid var(--Neutral-Gray-4); */
    width: 100%;
    /* margin-right: -32px; */
}
.expandedBody {
    max-width: 903px;
    width: 100%;
    margin: auto;
    /* padding: 32px; */
    padding-top: 16px;
    padding-bottom: 1px;
}
.question {
    color: var(--Brand-Primary);

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-bottom: 6px;
}
.textarea {
    border-radius: 4px;
    border: 1px solid var(--Neutral-Gray-5);
    border-bottom: 1px solid var(--Neutral-Gray-6);
    width: 100%;
    margin-bottom: 32px;
}
.submitBtn {
    border-radius: 4px;
    background: var(--Brand-Secondary);
    height: 32px;
    /* width: 71px; */
    color: white;
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    border: none;
    padding: 5px 12px;
}
.submitBtn:disabled {
    background-color: var(--Neutral-Gray-5);
}
.ansLabel {
    color: var(--Neutral-Gray-6);

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-bottom: 8px;
}
.answer {
    color: #000;
    /* Large/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    margin-bottom: 32px;
}
.rowMenu {
    display: flex;
    align-items: center;
}
.clearText {
    color: var(--Brand-Secondary);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    cursor: pointer;
}
.noResponse {
    color: var(--RAG-Red, #b00020);

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-bottom: 32px;
}
.inputCheckbox {
    display: flex;
    cursor: pointer;
    align-items: center;
}
.inputCheckbox > div {
    margin-right: 16px !important;
    border-color: var(--Brand-Primary);
}
.inputCheckbox > div > div {
    background-color: white;
}
.maturityScoreBoard {
    border-radius: 4px;
    background: var(--Brand-Primary);
    width: 100%;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 32px;
    padding-top: 16px;
    color: white;
    /* H5/Regular */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 24px */
    text-align: center;
}
.roundCirlcle {
    width: 160px;
    height: 160px;
    border-radius: 100%;
    border: 10px solid green;
    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    /* H1/Bold */
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 38.4px */
    margin: auto;
    margin-top: 24px;
}
