@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

.viewHeight {
    height: calc(100vh - 60px);
    background-color: var(--light);
}

.headerDiv {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 47px;
}

.headerDiv > h4 {
    font-size: 20px;
    font-family: 'Open Sans';
}

.orangeText {
    font-size: 12px;
    font-family: 'Open Sans';
    color: var(--Brand-Secondary);
}

.regularFont {
    font-size: 12px;
}

.tableHeader {
    background-color: #e6eaef;
    height: 40px;
}

.smallCol {
    width: 80px;
}

.tableRow {
    background: white;
    height: 40px;
    border-bottom: solid 1px rgba(0, 49, 73, 0.5);
    cursor: pointer;
}

.tableRow:hover,
.folderRow:hover {
    background-color: #f0f4f8;
}

.folderRow {
    background: white;
    height: 40px;
    cursor: pointer;

    border-bottom: solid 1px rgba(0, 49, 73, 0.1);
}

.wsName {
    font-size: 12px;
    font-weight: bold;
    font-family: 'Open Sans';
}

.roundCheckbox {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 13px;
    width: 13px;
    border: 1px solid var(--primary);
    border-radius: 20px !important;
}

/* Define the custom checkbox appearance */
.roundCheckbox:checked {
    background-color: var(--primary);
}

.roundCheckbox:checked::before {
    content: '' !important;
    /* Unicode for the tick icon in Font Awesome */
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    font-size: 8px;
    color: white;
    position: absolute;
    padding-left: 1.5px;
    padding-top: 0px;
}

.accentIcon {
    font-size: 7px;
    color: var(--info);
    position: relative;
    top: -8px;
    left: -2px;
}

.confirmationModalBody {
    width: 480px;
    border: solid 1px var(--danger);
    border-radius: 12px;
    /* display: flex; */
    min-height: 160px;
    padding: 0px;
}

.modalMainBody > div > div {
    border-radius: 12px !important;
    width: auto !important;
    border: none;
}

.redBgWarnModal {
    background-color: var(--danger);
    width: 120px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.confirmationModalMsg {
    padding: 24px;
    flex: 1;
}

.confirmationModalMsg > h3 {
    font-size: 24px;
    font-weight: bold;
}

.confirmationModalMsg > p {
    font-size: 14px;
    font-weight: normal;
}

.orangeLink {
    color: var(--Brand-Secondary);
    cursor: pointer;
    font-size: 12px;
}
