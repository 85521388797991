.header {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    background-color: var(--Brand-Primary);
}
.projectName {
    font-weight: 700 !important;
    color: white;
    margin-bottom: 0px;
    margin-right: 16px;
}
.inputNameDiv {
    display: flex;
    align-items: center;
}
.lensLogo {
    margin-left: 50px;
    margin-right: 239px;
    width: 73px;
}
.settingLogo {
    margin-left: 24px;
    margin-right: 26px;
}
.searchInput {
    width: 400px;
    height: 32px;
}
.leftContent {
    display: flex;
    align-items: center;
}
.menuLinkText {
    color: var(--Brand-Primary);
    /* Body/Regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: 16px;
}

@media (max-width: 1036px) {
    .lensLogo {
        margin-right: 50px;
    }
}
