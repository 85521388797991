.confirmationModalBody {
    width: 312px;
    /* border: solid 1px var(--danger); */
    border-radius: 12px;
    /* display: flex; */
    min-height: 160px;
    padding: 0px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
}
.modalMainBody {
    z-index: 100000000000 !important;
}

.modalMainBody > div > div {
    border-radius: 12px !important;
    width: auto !important;
    border: none;
    margin: auto;
}

.redBgWarnModal {
    background-color: var(--danger);
    width: 120px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.confirmationModalMsg {
    padding: 24px;
    flex: 1;
}

.confirmationModalMsg > h3 {
    font-size: 24px;
    font-weight: bold;
}

.confirmationModalMsg > p {
    font-size: 14px;
    font-weight: normal;
}

.orangeLink {
    color: var(--Brand-Secondary);
    cursor: pointer;
    font-size: 12px;
}

@media (max-width: 500px) {
    .confirmationModalBody {
        width: 100%;
    }
    .modalMainBody > div > div {
        width: 100% !important;
    }
}
/* .menuStyle:hover .menuStyle > div {
    background-color: red;
} */
.secondaryBtn {
    padding-left: 12px;
    padding-right: 12px;
    height: 32px;
    background-color: var(--Brand-Secondary);
    color: #fff;
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    border: none;
    border-radius: 4px;
}
.message {
    color: #000;
    text-align: center;
    /* Large/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    margin-bottom: 34px;
}
