@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

.container {
    /* padding: 30px; */
    padding-top: 18px;
}

.sectionTitle {
    font-size: 24px;
    color: var(--primary);
}

.rowBody {
    border-bottom: 0.7px solid var(--muted);
    padding: 10px;
    align-items: center;
}

.avatar {
    height: 24px;
    width: 24px;
    border-radius: 100px;
    background-color: var(--primary);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: OpenSans;
    font-size: 10px;
}

.avatarName {
    margin: 0px;
    font-size: 16px;
    line-height: 15px;
    color: var(--primary);
    margin-bottom: 2px;
}

.avatarOrg {
    margin: 0px;
    font-size: 13px;
    color: var(--primary);
    font-weight: bold;
}

.orangeText {
    margin: 0px;
    font-size: 16px;
    line-height: 15px;
    color: var(--Brand-Secondary);
    font-weight: bold;
    cursor: pointer;
}

.addScreenBody {
    margin: auto;
    max-width: 600px;
}

.targetHeading {
    object-fit: contain;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.44;
    letter-spacing: normal;
    color: var(--primary);
}

.topCard {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 19px;
    padding-bottom: 19px;
    border-bottom: solid 2px rgba(130, 154, 177, 0.2);
    background-color: white;
    object-fit: contain;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: bold;
    color: var(--primary);

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contentCard {
    border-bottom: solid 1px rgba(130, 154, 177, 0.5);
    background-color: white;
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    justify-content: space-between;
}

.contentCardTitle {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.64;
    letter-spacing: normal;
    color: var(--primary);
    margin: 0px;
}

.contentCardSub {
    font-size: 12px;
    font-style: italic;
    line-height: 1.67;
    letter-spacing: normal;
    color: var(--muted);
    margin: 0px;
}

.contentCardBoldSub {
    font-size: 12px;
    font-weight: 600;
    font-style: italic;
    font-weight: bold;
    line-height: 1.67;
    letter-spacing: normal;
    color: var(--primary);
}

.closeIcon {
    cursor: pointer;
    margin-right: 5px;
}

.menuIcon {
    position: absolute;
    left: 18px;
    top: 15px;
    cursor: pointer;
}

.menuTriangle {
    position: absolute;
    top: 14.6px;
    width: 32px;
    color: #c1c5c8;
    padding-top: 5px;
    z-index: 10;
    left: -7px;
}

.labelAddQuestion {
    font-size: 12px;
}

.smallButton {
    height: 24px;
    font-size: 12px;
    padding-top: 2px;
    padding-left: 16px;
    padding-right: 16px;
}

.smallFont {
    font-size: 12px !important;
}

.xSmallFont {
    font-size: 10px !important;
    font-style: italic;
}

.orangeBtnText {
    color: var(--Brand-Secondary);
    cursor: pointer;
}

.invitedText {
    color: var(--info);
    margin: 0px;
    font-size: 12px;
    font-style: italic;
}

.permissionBody {
    background-color: white;
    padding-top: 15px;
}

.tableHeader {
    background-color: #e6eaef;
    height: 40px;
    padding: 10px;
    font-size: 12px;
    font-family: OpenSans;
    color: var(--primary);
    line-height: 1.3;
}

.parentRow {
    height: 40px;
    border-bottom: 1px solid var(--primary);
    font-size: 12px;
    font-weight: bold;
    color: var(--primary);
}

.childRow {
    height: 40px;
    border-bottom: 1px solid rgba(0, 49, 73, 0.1);
    font-size: 12px;
    color: var(--primary);
}

.workflowText {
    font-style: italic;
    color: var(--muted);
}

.infoIcon {
    color: var(--info);
    position: absolute;
    top: 6px;
    left: 66px;
}

.permissionChecBody {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    /* padding-left: 3px;
    padding-right: 3px; */
    font-size: 12px;
    border-bottom: solid 1px rgba(130, 154, 177, 0.5);
}

.groupBtn {
    background-color: white;
    border: 1px solid var(--Brand-Secondary);
    color: var(--Brand-Secondary);
    border-radius: 4px;
}

.editUserText {
    font-size: 12px;
    font-style: italic;
}

.workstreamSelect {
    width: 100%;
    height: 40px;
    border: 1px solid #ced4da !important;
    font-size: 16px;
    padding-left: 10px;
}

.filterSelector {
    border-radius: 4px;
    border: 1px solid var(--muted);
    background-color: white;
    /* width: 175px; */
    min-height: 36px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: var(--primary);
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    user-select: none;
    margin-right: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: var(--muted);
}

.filterSelector + div {
    transform: translate3d(0px, 38px, 0px) !important;
    inset: -14px auto auto 0px !important;
}

.cosWSOption {
    color: var(--muted);
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
}

.cosWSOption:hover {
    color: var(--primary);
    background-color: #ddd;
}

.wsDelete {
    display: none;
}

.disabledWsOption:hover .wsDelete {
    display: block;
}

.addOptionsWS {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: var(--muted);
    padding-left: 12px;
    padding-right: 12px;
    height: 36px;
}

.smallButton:disabled {
    background-color: var(--Brand-Secondary);
    opacity: 0.6;
    outline: none;
    border: none;
}

.emailInputContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    font-size: 14px;
    line-height: 1.5;
    min-height: 38px;
    cursor: text;
}

.emailChip {
    display: flex;
    align-items: center;
    background-color: #eee;
    color: #333;
    border-radius: 16px;
    padding: 4px 8px;
    margin: 4px;
    font-size: 14px;
    line-height: 1.5;
    max-width: 95%;
}

.emailInput {
    border: none;
    outline: none;
}

.emailChip:hover {
    background-color: #ddd;
}

.removeEmailButton {
    background-color: transparent;
    border: none;
    color: #888;
    cursor: pointer;
    font-size: 12px;
    margin-left: 4px;
    outline: none;
    padding: 0;
}

.removeEmailButton:hover {
    color: #555;
}

.addGroupBox {
    width: 365px;
    height: 150px;
    margin-top: 51px;
    /* padding: 31px 107px 31px 108px; */
    padding-top: 41px;
    object-fit: contain;
    border-radius: 10px;
    border: dashed 2px var(--Brand-Secondary);
    background-color: white;
    font-family: OpenSans;
    font-size: 20px;
    text-align: center;
    color: var(--Brand-Secondary);
    cursor: pointer;
}

.addGroupBox:hover {
    opacity: 0.7;
}

.groupBody {
    width: 28.33vw;
    margin-right: 15px;
}

.groupsContainer {
    width: fit-content;
    display: flex;
    height: calc(100vh - 200px);
    overflow: auto;
}

.bottomCard {
    height: 50px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Brand-Secondary);
}

.confirmationModalBody {
    width: 480px;
    border: solid 1px var(--danger);
    border-radius: 12px;
    /* display: flex; */
    min-height: 160px;
    padding: 0px;
}

.modalMainBody > div > div {
    border-radius: 12px !important;
    width: auto !important;
    border: none;
}

.redBgWarnModal {
    background-color: var(--danger);
    width: 120px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.confirmationModalMsg {
    padding: 24px;
    flex: 1;
}

.confirmationModalMsg > h3 {
    font-size: 24px;
    font-weight: bold;
}

.confirmationModalMsg > p {
    font-size: 14px;
    font-weight: normal;
}

.orangeLink {
    color: var(--Brand-Secondary);
    cursor: pointer;
    font-size: 12px;
}

.disabledClass {
    opacity: 0.7;
    pointer-events: none;
}

.disabledWsOption {
    background: #c6c8ca8c;
}

.wsDropdown > div {
    top: 0px !important;
}

.wsChip {
    border: 1px solid;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 5px;
    margin-bottom: 2px;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.hoverMenuContent {
    display: none;
}

.dropdown:hover .hoverMenuContent {
    display: block;
}

.dropdown:hover .quickAddIcon {
    color: var(--primary);
}

.hoverMenuTriangle {
    position: absolute;
    top: 14.6px;
    width: 32px;
    color: #c1c5c8;
    padding-top: 5px;
    z-index: 10;
}

.hoverMenuCont {
    width: 175px;
    position: absolute;
    right: -0px;
    min-width: 10rem;
    padding: 9px;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    z-index: 4;
    overflow-y: auto;
    max-height: 175px;
    border-radius: 4px;
    box-shadow: 1px 2px 4px 0 rgb(130 154 177 / 30%);
    border: solid 1px rgba(130, 154, 177, 0.2);
    font-size: 12px;
}

.hoverMenuCont ul {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
}

.hoverMenuCont ul li a {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    text-align: inherit;
    text-decoration: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    word-wrap: break-word;
    color: #829ab1;
    box-sizing: border-box;
}

.hoverMenuCont ul li a:hover {
    background-color: #e9ecef;
}

.wsChipMenuItem {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.wsChipMenuItem a {
    color: var(--primary);
}

.emailError {
    /* float: right; */
    font-size: 12px;
    color: #ff000094;
}

.tourText {
    color: var(--Brand-Secondary);
    cursor: pointer;
    font-size: 14px;
}

.regularText {
    font-size: 14px;
}

.permissionDropdownMenu {
    height: 56px;
    padding: 11px 16px;
}

.infoTextItalic {
    font-size: 12px;
    /* font-weight: bold; */
    font-style: italic;
    margin-top: 10px;
}

.borderBtn {
    /* border-color: var(--Brand-Secondary); */
    outline: none;
    background: white !important;
    color: var(--Brand-Secondary) !important;
    border: 1.5px solid var(--Brand-Secondary) !important;
}

.headerBody {
    border-bottom: solid 1px var(--muted);
    padding-bottom: 19px;
    margin-bottom: 27px;
}

.noDataText {
    font-size: 24px;
    color: var(--primary);
}

.roundCheckbox {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 13px;
    width: 13px;
    border: 1px solid var(--primary);
    border-radius: 20px !important;
}

/* Define the custom checkbox appearance */
.roundCheckbox:checked {
    background-color: var(--primary);
}

.roundCheckbox:checked::before {
    content: '' !important;
    /* Unicode for the tick icon in Font Awesome */
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    font-size: 8px;
    color: white;
    position: absolute;
    padding-left: 1.5px;
    padding-top: 0px;
}
