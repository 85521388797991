.editBody {
    width: 100%;
    height: calc(100vh - 48px);
    overflow: auto;
    background: var(--Neutral-Gray-3);
    /* padding-top: 24px; */
}
.componentCard {
    /* background-color: white; */
}
.componentTitle {
    color: #000;
    /* H6/Bold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 21.6px */
    margin-bottom: 56px;
    /* margin-top: 88px; */
}

.fieldLabel {
    color: var(--Brand-Primary);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-bottom: 5.5px;
}
.required {
    color: #c50f1f;
}
.inputField {
    width: 100%;
    border-color: var(--Neutral-Gray-5);
    border-bottom: 1px solid var(--Neutral-Gray-6) !important;
    height: 32px;
}
.textareaField {
    width: 100%;
    border-color: var(--Neutral-Gray-5);
    border-bottom: 1px solid var(--Neutral-Gray-6) !important;
}
.selectField > select {
    border-color: var(--Neutral-Gray-5);
    border-bottom: 1px solid var(--Neutral-Gray-6) !important;
}
.submitButton {
    height: 32px;
    color: var(--Neutral-White, #fff);

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    border: none;
    border-radius: 4px;
    background-color: var(--Brand-Secondary);
    padding-left: 15px;
    padding-right: 15px;
}
.uploadBox {
    border-radius: 4px;
    border: 1px solid var(--Brand-Secondary);
    background: var(--secondary-5);
    height: 160px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--Brand-Secondary);
    text-align: center;
    /* Body/Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 16.8px */
    margin-bottom: 32px;
    cursor: pointer;
}
.uploadItemsList {
    margin-top: 16px;
    margin-bottom: 32px;
}
.fileListItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(130, 154, 177, 0.5);
    padding: 5px 0px;
}
.linkBtn {
    color: var(--Brand-Secondary);
    cursor: pointer;
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
}
.logoDiv {
    height: 160px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--Neutral-Gray-6);
}
.logoImage {
    display: block;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    min-height: auto;
    min-width: 100%;
    object-fit: contain;
    opacity: 1;
    padding: 10px;
}
.currencyDiv {
    width: 72px;
    height: 32px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background: var(--Neutral-Gray-5, #c6c8d2);
    color: var(--Neutral-White, #fff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -3px;
    z-index: 1;
}
.subHeading {
    color: #000;
    /* Small/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 33.5px;
    line-height: 120%; /* 14.4px */
}

.navLinkItem {
    padding: 16px;
    padding-right: 24px;
    text-align: right;
    border-right: 1px solid var(--Neutral-Gray-6, #989bad);
    cursor: pointer;
    color: var(--Brand-Primary, #1b2149);
    text-align: right;

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
}
.navLinkBody {
    margin-top: 72px;
}
.selectednavItem {
    font-weight: 700;
    border-right: 4px solid var(--Brand-Secondary);
    padding-right: 20px;
}
.buttonDiv {
    /* height: 48px; */
    /* margin-bottom: 88px; */
}
.formBodies {
    height: calc(100vh - 78px);
    padding-top: 24px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
}

.formBodies::-webkit-scrollbar {
    width: 0;
}
