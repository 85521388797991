.loaderBody {
    height: 80vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.ndaBody {
    height: 100vh;
    width: 100%;
}
.ndaHeader {
    height: 48px;
    width: 100%;
    background: var(--Brand-Primary);
    padding-left: 84px;
    padding-right: 48px;
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.projectName {
    color: var(--Neutral-White, #fff);
    /* Large/Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    margin-bottom: 0px;
}
.ndaTitle {
    color: var(--Neutral-White, #fff);
    /* Large/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    margin-bottom: 0px;
}
.headerButton {
    height: 32px;
    background-color: var(--Brand-Secondary);
    color: var(--Neutral-Foreground-On-Brand-Rest, #fff);
    border-radius: 4px;
    border: none;
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    padding: 6px 24px;
}

.docContainer {
    height: calc(100vh - 48px);
}
.docContainer > div > iframe {
    height: calc(100vh - 55px) !important;
}
