@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700&display=swap');

.headerSection {
    padding-top: 20.5px;
    padding-bottom: 20.5px;
    /* border-bottom: 1px solid rgba(130, 154, 177, 0.5); */
}

.headerTitle {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Open Sans';
    font-weight: bold;
    color: var(--primary);
}

.tabViewBody {
    background-color: white;
    padding: 11px 18px;
}

.menuButton {
    background-color: white;
    padding: 8px 18px;
    height: 36px;
    width: fit-content;
    margin-top: 16px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 12px;
    color: var(--primary);
}

.questionsLength {
    font-size: 12px;
    color: var(--muted);
    margin-bottom: 0px;
}

.smallButton {
    height: 24px;
    font-size: 12px;
    padding-top: 2px;
    padding-left: 16px;
    padding-right: 16px;
}

.colFilterSelector {
    border-radius: 4px;
    border: 1px solid var(--muted);
    background-color: white;
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: var(--muted);
    cursor: pointer;
    user-select: none;
    width: 120px;
    display: flex;
    justify-content: center;
}

.colFilterSelector + div {
    transform: translate3d(0px, 38px, 0px) !important;
    inset: -14px auto auto 0px !important;
}

.filterBody {
    width: 156px;
    background-color: white;
    border-radius: 4px;
    padding: 0px 15px 0px 15px;
}

@media (max-width: 991px) {
    .filterBody {
        width: 100%;
    }
}

.smallFont {
    font-size: 12px;
}

.tableHeader {
    background: #e6eaef;
    color: var(--primary);
    font-size: 12px;
    height: 40px;
    position: sticky;
    top: 0px;
    z-index: 1;
    font-weight: 600;
    font-family: 'Open Sans';
}

.tableContainer {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 260px);
}

.tableRow {
    background-color: white;
    height: 40px;
    color: var(--primary);
    font-size: 12px;
    border-bottom: solid 1px rgba(130, 154, 177, 0.2);
    font-weight: 600;
    font-family: 'Open Sans';
}

.fileUploadBody {
    height: 35px;
    border-radius: 4px;
    border: 1px solid var(--Neutral-Gray-5);
    width: 100%;
    color: var(--Neutral-Gray-6);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
}

.fileUploadBody > label {
    height: 34px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background: var(--Neutral-Gray-5);
    color: white;
    font-size: 14px;
    padding: 6px;
    border-right: 1px solid;
    cursor: pointer;
    margin-right: 10px;
}

.sidebarBody {
    /* padding: 16px; */
    /* min-width: 400px; */
}

.claimCheckbox {
    height: 20px !important;
    width: 20px !important;
}

.claimCheckbox:checked::before {
    font-size: 14px !important;
    padding-top: 1px !important;
    padding-left: 1px !important;
}

.orangeBtnText {
    color: var(--Brand-Secondary);
    font-size: 12px;
    cursor: pointer;
    margin: 0px;
}

.blueText {
    font-size: 12px;
    color: var(--info);
}

.ragBadge {
    height: 16px;
    width: 16px;
    border-radius: 100px;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.hoverMenuContent {
    display: none;
}

.dropdown:hover .hoverMenuContent {
    display: block;
}

.dropdown:hover .quickAddIcon {
    color: var(--primary);
}

.hoverMenuCont {
    width: 175px;
    position: absolute;
    right: -0px;
    min-width: 10rem;
    padding: 9px;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    z-index: 4;
    overflow-y: auto;
    max-height: 175px;
    border-radius: 4px;
    box-shadow: 1px 2px 4px 0 rgb(130 154 177 / 30%);
    border: solid 1px rgba(130, 154, 177, 0.2);
    font-size: 12px;
}

.hoverMenuCont ul {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
}

.hoverMenuCont ul li a {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    text-align: inherit;
    text-decoration: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    word-wrap: break-word;
    color: #829ab1;
    box-sizing: border-box;
}

.hoverMenuCont ul li a:hover {
    background-color: #e9ecef;
}

.tagChip {
    background-color: var(--info);
    font-size: 12px;
    padding: 3px 10px;
    border-radius: 20px;
    color: white;
    margin-right: 10px;
    margin-bottom: 10px;
}

.viewObsSection {
    border-top: dashed 1px var(--muted);
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.viewObsSection2 {
    border-top: solid 1px rgba(130, 154, 177, 0.5);
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.regularText {
    font-size: 14px;
}

.swotBadge {
    font-size: 10px;
    background-color: #fc9c24;
    color: white;
    padding: 2px 8px;
    border-radius: 4px;
}

.workstreamSelect {
    width: 100%;
    height: 40px;
    border: 1px solid #ced4da !important;
    font-size: 16px;
    padding-left: 10px;
}

.filterSelector {
    border-radius: 4px;
    border: 1px solid var(--muted);
    background-color: white;
    /* width: 175px; */
    min-height: 36px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: var(--primary);
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    user-select: none;
    margin-right: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: var(--muted);
}

.filterSelector + div {
    transform: translate3d(0px, 38px, 0px) !important;
    inset: -14px auto auto 0px !important;
}

.cosWSOption {
    color: var(--muted);
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
}

.cosWSOption:hover {
    color: var(--primary);
    background-color: #ddd;
}

.wsDelete {
    display: none;
}

.wsDropdown > div {
    top: 0px !important;
}

.disabledWsOption {
    background: #c6c8ca8c;
}

.disabledWsOption:hover .wsDelete {
    display: block;
}

.addOptionsWS {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: var(--muted);
    padding-left: 12px;
    padding-right: 12px;
    height: 36px;
}

.wsChip {
    border: 1px solid;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 5px;
    margin-bottom: 2px;
}

.tableEditorText {
    height: 16px;
    overflow: hidden;
}

.confirmationModalBody {
    width: 480px;
    border: solid 1px var(--danger);
    border-radius: 12px;
    /* display: flex; */
    min-height: 160px;
    padding: 0px;
}

.modalMainBody > div > div {
    border-radius: 12px !important;
    width: auto !important;
    border: none;
}

.redBgWarnModal {
    background-color: var(--danger);
    width: 120px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.confirmationModalMsg {
    padding: 24px;
    flex: 1;
}

.confirmationModalMsg > h3 {
    font-size: 24px;
    font-weight: bold;
}

.confirmationModalMsg > p {
    font-size: 14px;
    font-weight: normal;
}

.orangeLink {
    color: var(--Brand-Secondary);
    cursor: pointer;
    font-size: 12px;
}

.accordonText {
    color: var(--muted);
    line-height: 1px;
    font-size: 12px;
}

.filterTag {
    height: 24px;
    background: var(--muted);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 12px;
    font-size: 12px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    text-transform: capitalize;
    cursor: pointer;
}

.richText img {
    width: 100%;
}
.fieldLabel {
    color: var(--Brand-Primary);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-bottom: 5.5px;
}
.customSelect {
    border: 1px solid var(--Neutral-Gray-5);
    border-bottom: 1px solid var(--Neutral-Gray-6);
    border-radius: 4px;
    height: 32px;
    font-size: 14px;
    padding: 5px;
}
.customReactQuill > div > div:nth-child(2) {
    border: 1px solid var(--Neutral-Gray-5) !important;
    border-bottom: 1px solid var(--Neutral-Gray-6) !important;
    border-radius: 4px;
}
.ragCircle {
    height: 23px;
    width: 23px;
    border: 1px solid var(--Neutral-Gray-4);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}
.ragCircleInside {
    height: 15px;
    width: 15px;
    background-color: var(--Neutral-Gray-4);
    border-radius: 100px;
}
.sidebarTitle {
    color: var(--Brand-Primary);

    /* Large/Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 40px;
    line-height: 120%; /* 19.2px */
}
.quillShowToolbar {
    position: absolute;
    right: 34px;
    top: -5px;
}
.saveButton {
    width: 80px;
    height: 32px;
    background-color: var(--Brand-Secondary);
    border: none;
}
.arrowsDiv {
    display: flex;
    justify-content: space-between;
    width: 70px;
}
