.body {
    background: linear-gradient(180deg, #80e5ff 0%, #a59eff 100%);
}

.dotedBg {
    position: relative;
    height: calc(100vh - 32px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dotedBg::before {
    content: '';
    background-image: url(/public/assets/images/dotedBg.png);
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.75; /* Adjust the opacity value */
    mix-blend-mode: soft-light;
}

/* Adjust this opacity value to control the transparency */
.dotedBg-content {
    position: relative;
    z-index: 1;
}

.formCard {
    width: 464px;
    z-index: 1;
}
.formHeader {
    border-radius: 3px 3px 0px 0px;
    background: var(--Brand-Primary);
    height: 56px;
    width: 100%;
    padding: 8px 24px;
}

.formBody {
    background-color: white;
    width: 100%;
    border-radius: 0px 0px 3px 3px;
    padding: 32px;
}
.formLabel {
    color: var(--Brand-Primary);

    /* H6/Bold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 21.6px */
    margin-bottom: 8px;
}
.formSubtitle {
    color: var(--Brand-Primary);
    margin-bottom: 32px;
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
}
.fieldLabel {
    color: var(--Neutral-Gray-6, #989bad);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-bottom: 3.5px;
}
.inputField {
    border-bottom: 1px solid var(--Neutral-Gray-6);
    height: 32px;
    border-radius: 4px;
    margin-bottom: 32px;
}
.footer {
    height: 32px;
    width: 100%;
    background-color: var(--Brand-Primary);
    padding-right: 96px;
    text-align: right;
    padding-top: 6px;
}
.footer > a {
    cursor: pointer;
    color: var(--Neutral-White, #fff);

    /* Small/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    margin-left: 44px;
}

.submitBtn {
    border-radius: 4px;
    background: var(--Brand-Secondary);
    color: var(--Neutral-Foreground-On-Brand-Rest, #fff);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    height: 40px;
    padding-left: 18px;
    padding-right: 18px;
    border: none;
}

.responseBody > h5 {
    margin-bottom: 8px;
    color: var(--Brand-Primary);

    /* H6/Bold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 21.6px */
}
.responseBody > p {
    margin-bottom: 0px;
    color: var(--Brand-Primary);

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
}
.otpField {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
}
.otpField > input {
    width: auto;
    border: none;
    outline: none;
    width: 14%;
    border-bottom: 1px solid var(--Neutral-Gray-6);
    text-align: center;
    height: 48px;
}
.rememberMeSection {
    margin-top: 32px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    width: fit-content;
}
.rememberBeText {
    color: var(--Brand-Primary);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-left: 16px;
    cursor: pointer;
}
.secondaryBtnLink {
    color: var(--Brand-Secondary);

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
}
