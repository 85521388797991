@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

.viewHeight {
    height: calc(100vh - 60px);
    background-color: var(--light);
}

.headerDiv {
    display: flex;
    justify-content: space-between;
    padding-top: 18px;
    padding-bottom: 12px;
}

.title {
    font-size: 16px;
    color: var(--primary);
    font-weight: bold;
    font-family: 'Open Sans';
    margin: 0;
}

.countMain {
    font-size: 16px;
    color: var(--primary);
    font-weight: bold;
    font-family: 'Open Sans';
    margin: 0px;
}

.leftSideBody {
    width: 100%;
    background-color: white;
    border-radius: 4px;
    max-height: 67vh;
    min-height: 400px;
    overflow-y: auto;
}

.docContainer {
    height: calc(100vh - 140px);
}
.docContainer > div > iframe {
    height: calc(100vh - 145px);
}

.rightSideBody {
    width: 100%;
    background-color: white;
    border-radius: 4px;
    /* height: 65vh;
    overflow-y: auto; */
    padding: 19px 0px 19px 0px;
}
.filesBody {
    position: relative;
    height: calc(100vh - 420px);
    overflow-y: auto;
}

.subTitle {
    font-size: 14px;
    color: var(--primary);
}

.blueText {
    font-size: 12px;
    color: var(--info);
}

.headerHeading {
    font-size: 12px;
    color: var(--primary);
    cursor: pointer;
    margin-left: 10px;
}

.menuItem {
    font-size: 12px;
    color: var(--primary);
    cursor: pointer;
}

.menuItem:hover {
    color: var(--Brand-Secondary);
}

.requestedText {
    font-size: 13px;
    color: var(--muted);
}
.stickyHeader {
    padding: 0px 20px 18px 20px;
    height: 44px;
    /* position: relative; */
}
.rightHeaderBody {
    display: flex;
    justify-content: end;
    align-items: center;
    background-color: white;
    /* position: fixed;
    right: 25px; */
}

.infoCircle {
    margin-top: -6px;
}

.addDocText {
    font-size: 16px;
    margin: 0px;
    cursor: pointer;
    color: var(--Brand-Secondary);
}

.rowHiddenItem {
    display: none;
}

.tableHeader {
    background: rgba(130, 154, 177, 0.2);
    color: var(--primary);
    font-size: 12px;
    height: 40px;
    /* font-weight: bold; */
    font-family: 'Open Sans';
}

.smallTableHeader {
    background: rgba(130, 154, 177, 0.2);
    color: var(--primary);
    font-size: 12px;
    height: 24px;
    font-weight: bold;
    font-family: 'Open Sans';
}

.tableRow {
    border-bottom: solid 1px rgba(130, 154, 177, 0.2);
    height: 40px;
    /* cursor: pointer; */
    font-size: 12px;
}

.tableRow:hover .rowHiddenItem {
    display: block;
}

.smallAvatar {
    height: 15px;
    width: 15px;
    background-color: #666666;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    color: white;
}

.smallBlueLabel {
    font-size: 10px;
    color: var(--info);
}

.smallLabelBox {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(130, 154, 177, 0.5);
}

.fileName {
    padding-left: 24px;
    width: 370px;
}

.modalBody {
    padding: 5px 33px 20px 33px;
}

.uploadDocTitle {
    font-size: 28px;
    font-weight: bold;
    font-family: 'Open Sans';
}

.descriptionIRL {
    font-size: 12px;
    margin-top: 17px;
    padding-left: 23px;
    padding-right: 23px;
    margin-bottom: 19px;
}

.descriptionSmall {
    font-size: 13px;
    margin-top: 16px;
    margin-bottom: 35px;
}

.orangeButton {
    position: absolute;
    right: 0px;
    background: var(--Brand-Secondary);
    height: 55px;
    color: white;
    justify-content: center;
    align-items: center;
    display: flex;
}

.orangeBorderedButton {
    outline: none;
    background: white !important;
    color: var(--Brand-Secondary) !important;
    border: 1.5px solid var(--Brand-Secondary) !important;
    height: 24px;
    font-size: 12px;
    padding-top: 2px;
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 16px;
}

.sidebarBody {
    width: 100%;
    margin-top: 12px;
    background-color: white;
    border-radius: 4px;
    padding: 15px 22px 15px 22px;
}

.sidebarLabel {
    font-size: 20px;
    font-weight: bold;
    font-family: 'Open Sans';
    margin-bottom: 24px;
}

.orangeText {
    color: var(--Brand-Secondary);
    cursor: pointer;
    font-weight: bold;
}

.orangeLink {
    color: var(--Brand-Secondary);
    cursor: pointer;
    font-size: 12px;
}

.tourText {
    color: var(--Brand-Secondary);
    cursor: pointer;
    font-size: 14px;
}

.analyticsText {
    color: var(--Brand-Secondary);
    cursor: pointer;
    font-size: 12px;
    margin-bottom: 10px;
    margin-left: 17px;
    display: inline-block;
}

.smallButton {
    height: 24px;
    font-size: 12px;
    padding-top: 2px;
    padding-left: 16px;
    padding-right: 16px;
}

.leftSideHeader {
    height: 36px;
    background: rgba(130, 154, 177, 0.2);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    /* padding-top:12px; */
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;
}

.headerCount {
    width: 40px;
    height: 20px;
    border-radius: 4px;
    border: solid 1px var(--muted);
    background-color: var(--muted);
    color: white;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.headerSearchInput {
    height: 24px;
    background: #f0f4f8;
    border-color: var(--muted);
    font-size: small;
    width: 250px;
}

.headerSearchIcon {
    height: 24px;
    width: 24px;
    background-color: var(--muted);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.leftSideRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 9px;
    padding-bottom: 9px;
    border-bottom: 1px solid #dae2e9;
}

.selectedLeftSideRow {
    background-color: rgba(51, 181, 229, 0.1);
}

.leftSideRow:hover {
    background-color: rgba(51, 181, 229, 0.1);
}

.smallFont {
    font-size: 12px;
}

.fileMetaBox {
    margin-top: 40px;
    border-bottom: 1px solid rgba(130, 154, 177, 0.5);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.fileMetaBox > div {
    flex-basis: 50%;
    margin-bottom: 15px;
}

.rowCount {
    width: 40px;
    height: 20px;
    border-radius: 4px;
    border: solid 1px var(--muted);
    background-color: white;
    color: var(--muted);
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selectedRowCount {
    color: var(--Brand-Secondary);
    border-color: var(--Brand-Secondary);
}

.regularCount {
    color: white;
    border-color: #006400;
    background-color: #006400;
}

.rightSideMenuBtn {
    height: 36px;
    /* width: 96px; */
    padding-left: 17px;
    padding-right: 17px;
    background: rgba(130, 154, 177, 0.2);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    cursor: pointer;
}

.selectedMenuBtn {
    /* color: var(--info); */

    background-color: white;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.hoverMenuContent {
    display: none;
}

.dropdown:hover .hoverMenuContent {
    display: block;
}

.dropdown:hover .quickAddIcon {
    color: var(--primary);
}

.hoverMenuTriangle {
    position: absolute;
    top: 14.6px;
    width: 32px;
    color: #c1c5c8;
    padding-top: 5px;
    z-index: 10;
}

.hoverMenuCont {
    width: 175px;
    position: absolute;
    right: -0px;
    min-width: 10rem;
    padding: 9px;
    z-index: 100000000;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    overflow-y: auto;
    max-height: 175px;
    border-radius: 4px;
    box-shadow: 1px 2px 4px 0 rgb(130 154 177 / 30%);
    border: solid 1px rgba(130, 154, 177, 0.2);
    font-size: 12px;
}

.hoverMenuCont ul {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
}

.hoverMenuCont ul li a {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    text-align: inherit;
    text-decoration: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    word-wrap: break-word;
    color: #829ab1;
    box-sizing: border-box;
}

.hoverMenuCont ul li a:hover {
    background-color: #e9ecef;
}

.blueBorderBox {
    border-radius: 4px;
    border: solid 1px var(--info);
    padding: 7px 16px 7px 16px;
    font-size: 12px;
    overflow-y: auto;
}

.AnalyticsFileCount {
    font-size: 16px;
    font-weight: bold;
}

.analyticPieChartText {
    text-align: center;
    /* position: relative; */
    margin-top: -38px;
    line-height: 4px;
}

.tableButton {
    width: 165px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px rgba(130, 154, 177, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    color: var(--info);
    margin-top: 12px;
}

.chip {
    height: 24px;
    border-radius: 12px;
    border: solid 1px var(--muted);
    color: var(--muted);
    font-size: 12px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 8px;
    margin-bottom: 12px;
}

.borderChip {
    border: solid 1px var(--info);
    color: var(--info);
}

.selectedChip {
    border: solid 1px var(--info);
    color: white;
    background-color: var(--info);
}

.filterSelector {
    border-radius: 4px;
    border: 1px solid var(--muted);
    background-color: white;
    /* width: 175px; */
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: var(--primary);
    padding-left: 17px;
    padding-right: 17px;
    cursor: pointer;
    user-select: none;
    margin-right: 8px;
}

.filterSelector + div {
    transform: translate3d(0px, 38px, 0px) !important;
    inset: -14px auto auto 0px !important;
}

.filterBody {
    width: 190px;
    background-color: white;
    border-radius: 4px;

    font-size: 12px;
}
.navigationIcons {
    font-size: 14px;
    cursor: pointer;
    color: var(--Brand-Secondary);
}
@media (max-width: 991px) {
    .filterBody {
        width: 100%;
    }
}

.filterRow {
    padding: 3px 17px 3px 17px;
    cursor: pointer;
}

.filterRow:hover {
    background-color: rgba(51, 181, 229, 0.1);
}

.noResult {
    text-align: center;
    margin-top: 26vh;
}

.fileUploadBody {
    height: 36px;
    border-radius: 4px;
    border: 1px solid var(--muted);
    width: 100%;
    color: var(--muted);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
}

.fileUploadBody > label {
    height: 34px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background: #ced4da;
    color: var(--muted);
    font-size: 14px;
    padding: 6px;
    border-right: 1px solid;
    cursor: pointer;
    margin-right: 10px;
}

.workstreamSelect {
    width: 100%;
    height: 40px;
    border: 1px solid #ced4da !important;
    font-size: 16px;
    padding-left: 10px;
}

.workstreamMenuItem {
    /* height: 30px; */
    border-bottom: 1px solid #ced4da !important;
    color: var(--primary);
    font-size: 12px;
    padding: 6px;
}

.workstreamMenuItem:hover {
    background-color: #ced4da;
}

.addWorkstreamChip {
    min-width: 255px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px var(--Brand-Secondary);
    padding: 8px 15px;
    color: var(--Brand-Secondary);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 15px;
    margin-bottom: 8px;
}

.wsChipsBody {
    display: flex;
    flex-wrap: wrap;
    margin-top: 21px;
    border-bottom: solid 1px #979797;
    padding-bottom: 25px;
    margin-bottom: 15px;
}

.defaultWSChip {
    min-width: 255px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px var(--muted);
    padding: 8px 15px;
    color: var(--muted);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 15px;
    margin-bottom: 8px;
    position: relative;
}

.selectedWsChip {
    background-color: var(--primary);
    border: none;
    color: white;
}

.defaultWSChip > span {
    width: 97%;
    text-align: center;
}

.wsChipMenuItem {
    display: flex;
    align-items: center;
}

.addWorkstreamList {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--primary);
    border-bottom: solid 1px rgba(130, 154, 177, 0.2);
    padding: 8px 0px;
    margin: 0;
}

.noFolderViewBody {
    border-radius: 8px;
    border: solid 1px rgba(130, 154, 177, 0.5);
    width: 100%;
    height: calc(100vh - 330px);
    margin-top: 32px;
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: center;
}

.tooltipStyle {
    width: 100px I !important;
}

.confirmationModalBody {
    width: 480px;
    border: solid 1px var(--danger);
    border-radius: 12px;
    /* display: flex; */
    min-height: 160px;
    padding: 0px;
}

.modalMainBody > div > div {
    border-radius: 12px !important;
    width: auto !important;
    border: none;
}

.redBgWarnModal {
    background-color: var(--danger);
    width: 120px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.confirmationModalMsg {
    padding: 24px;
    flex: 1;
}

.confirmationModalMsg > h3 {
    font-size: 24px;
    font-weight: bold;
}

.confirmationModalMsg > p {
    font-size: 14px;
    font-weight: normal;
}

.rolloverInfoIcon:hover {
    color: red;
}

.tableDragDrop {
    /* width: 95%; */
    border-radius: 4px;
    color: rgba(130, 154, 177, 0.5);
    margin-top: 20px;
    height: 180px;
    align-items: center;
    justify-content: center;
    display: flex;
    /* margin-left: 10px;
    margin-right: 10px; */
    border: 1px dashed #dce4eb;
    margin-left: 20px;
    margin-right: 20px;
}

.tableDragDrop > div {
    pointer-events: none;
}

.filesTable {
    pointer-events: none;
    z-index: 0;
    opacity: 0.5;
}

/* .tableDragDrop:hover{
    border: 1px dashed var(--muted);
} */

.dragging {
    border: 1px dashed var(--Brand-Secondary);
    color: var(--Brand-Secondary);
    /* position: absolute;
    top: -20px;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center; */
}
.draggingFolder {
    border: 1px dashed var(--Brand-Secondary);
    opacity: 0.5;
}

.tableBody {
    position: relative;
    z-index: 1000;
}

.fileListItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(130, 154, 177, 0.5);
    padding: 5px 0px;
}

.loaderBody {
    height: 80vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.userName {
    border-bottom: 0.5px solid var(--muted);
    padding-bottom: 5px;
    padding-top: 5px;
}

.claimCheckbox {
    height: 24px !important;
    width: 24px !important;
}

.claimCheckbox:checked::before {
    font-size: 14px !important;
    padding-top: 1px !important;
    padding-left: 3.5px !important;
}

.orangeBtnText {
    color: var(--Brand-Secondary);
    font-size: 12px;
    cursor: pointer;
    margin: 0px;
}

.descriptionText {
    margin: 16px;
    margin-top: 0;
    font-size: 12px;
    font-weight: normal;
    color: var(--primary);
}
