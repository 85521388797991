.viewHeight {
    padding: 30px 36px;
}
.addButton {
    border-radius: 4px;
    background: var(--Brand-Secondary);
    height: 32px;
    /* width: 105px; */
    border: none;
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    padding-left: 10px;
    padding-right: 10px;
}
.projectName {
    color: var(--Brand-Primary);
    /* Large/Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    margin-left: 32px;
    margin-bottom: 0;
}
.dataroomContentBody {
    /* margin-top: 30.5px; */
}
.leftPanelBody {
    height: calc(100vh - 111px);
    border-radius: 4px;
    overflow: auto;
}
.workstreamLabel {
    color: var(--Brand-Primary);
    padding: 16px;
    padding-bottom: 24px;
    /* Large/Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    margin: 0px;
    padding-top: 0px;
}
.panelItems {
    padding: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--Brand-Primary);
    /* Body/Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
    line-height: 120%; /* 16.8px */
}
.panelName {
    cursor: pointer;
    height: 48px;
    display: flex;
    align-items: center;
    width: 100%;
}
.panelFolderItem {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--Brand-Primary);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    padding-right: 22px;
    cursor: pointer;
}
.folderKebab {
    /* display: none !important; */
    margin-right: -5px;
}
.panelFolderItem:hover .folderKebab {
    display: block !important;
}
.panelFolderItem:hover .fileCount {
    /* display: none; */
}
.whiteBackground {
    width: 100%;
    height: calc(100vh - 295px);
    border-radius: 4px;
    padding: 16px;
    background-color: #fff;
    overflow: auto;
}
.sidebarWhite {
    height: calc(100vh - 167px);
    width: 100%;
    border-radius: 4px;
    padding: 16px;
    background-color: #fff;
    overflow: auto;
}
.addworkstreamtext {
    color: var(--Brand-Secondary);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    cursor: pointer;
}
.sidebarTitle {
    color: var(--Brand-Primary, #1b2149);
    /* Large/Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
}
.buttonLink {
    color: var(--Brand-Secondary);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-bottom: 16px;
    cursor: pointer;
}
.folderName {
    color: var(--Brand-Primary);
    /* Large/Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    margin-bottom: 17px;
}
.fodlerDesc {
    color: var(--Brand-Primary);

    /* Small/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    margin-bottom: 33px;
    /* min-height: 42px; */
}
.tableHeadItem {
    padding: 16px;
    color: var(--Brand-Primary);

    /* Body/Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 16.8px */
}
.tableItem {
    padding: 16px;
    color: var(--Brand-Primary);

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
}
.tableKebabMenu {
    visibility: hidden;
}
.tableRow {
    border-top: 1px solid var(--Neutral-Gray-4);
    cursor: pointer;
}
.tableRow:hover .tableKebabMenu {
    /* display: block !important; */
    visibility: visible;
}

.folderDetailTop {
    padding: 16px;
    padding-bottom: 0px;
}
.uploadBox {
    border-radius: 4px;
    border: 1px solid var(--Brand-Secondary);
    background: var(--secondary-5);
    height: 160px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--Brand-Secondary);
    text-align: center;

    /* Body/Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 16.8px */

    margin-top: 16px;
    margin-bottom: 32px;
}

.saveButton {
    border-radius: 4px;
    background: var(--Brand-Secondary);
    height: 32px;
    /* width: 64px; */
    padding-left: 16px;
    padding-right: 16px;
    border: none;
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
}
.routeText {
    color: var(--Brand-Primary);

    /* Body/Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    cursor: pointer;
}
.uploadItemsList {
    margin-top: 16px;
    margin-bottom: 32px;
}
.fileListItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(130, 154, 177, 0.5);
    padding: 5px 0px;
}
.addFolderLink {
    padding-left: 44px;
}
.noFolderText {
    color: var(--Brand-Secondary);

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    padding: 32px;
    cursor: pointer;
}
.addWorkstreamList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    height: 36px;
}
.infoIcon {
    visibility: hidden;
    margin-right: 8px;
    color: var(--Neutral-Gray-5);
}
.addWorkstreamList:hover .infoIcon {
    visibility: visible;
}
.addIcon {
    display: none;
}
.addWorkstreamList:hover {
    background: var(--secondary-5);
}
.addWorkstreamList:hover .addIcon {
    display: block !important;
}
.addWorkstreamList > span {
    color: var(--Brand-Primary);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
}
.createWsBody {
    padding-top: 10px;
}
.createFolderBody {
    padding-top: 46px;
}
.fieldLabel {
    color: var(--Brand-Primary);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 6px;
}
.inputField {
    width: 100%;
    margin-bottom: 24px;
    border: 1.5px solid var(--Neutral-Gray-5);
    border-bottom-color: var(--Neutral-Gray-6);
}

.fileSidebarHeader {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--Neutral-Gray-4);
}
.fileInfoName {
    overflow: hidden;
    color: var(--Brand-Primary, #1b2149);
    text-overflow: ellipsis;

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-left: 8px;
    margin-bottom: 0px;
}
.fileSidebarBody {
    padding: 16px;
}
.fileSidebarLabel {
    color: var(--Neutral-Gray-6);
    /* Small/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
}
.fileSidebarDesc {
    color: var(--Brand-Primary);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
}

/* File VIewer */
.FVHeader {
    background: var(--Brand-Primary);
    height: 48px;
    width: 100%;
    padding-top: 14.5px;
    padding-bottom: 14.5px;
    padding-left: 84px;
    padding-right: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.FVprojectName {
    color: white;
    /* Large/Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    margin: 0px;
}
.FVfilePath {
    color: white;
    /* Large/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    margin: 0px;
    display: flex;
    align-items: center;
    /* margin-left: 8px; */
}
.FVdirectionalBtn {
    height: 32px;
    padding: 5px 12px;
    border-radius: 4px;
    background: var(--Brand-Secondary);
    border: none;
    color: white;
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-left: 48px;
    margin-right: 48px;
}
.FVdirectionalBtn:disabled {
    pointer-events: none;
    background: var(--Neutral-Gray-6);
}
.FVfileCount {
    color: white;
    text-align: center;
    /* Large/Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
}
.docContainer {
    height: calc(100vh - 48px);
}
.docContainer > div > iframe {
    height: calc(100vh - 48px) !important;
}
.loaderBody {
    height: 80vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.analyticsText {
    color: var(--Brand-Secondary);

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    /* float: right; */
    /* margin-top: -33px; */
    cursor: pointer;
}
.secondaryLink {
    color: var(--Brand-Secondary);

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    cursor: pointer;
}
.analyticsHeader {
    padding: 24px 16px;
    background-color: white;
    border-radius: 4px;
    margin-bottom: 16px;
    width: 100%;
    color: var(--Brand-Primary);

    /* Large/Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
}
.analyticsSmallCard {
    padding: 16px;
    background-color: white;
    border-radius: 4px;
    width: 100%;
    height: 152px;
    overflow: auto;
}

.analyticeMenuText {
    color: var(--Neutral-Gray-6);
    margin-bottom: 34px;
    /* Large/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
}
.analyticFirstlatestFile {
    margin-top: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--Neutral-Gray-4);
    margin-bottom: 16px;
}
.pieChartText {
    position: absolute;
    top: 74px;
    text-align: center;
    width: 100%;
}
.pieChartText > h4 {
    color: var(--Brand-Primary);
    text-align: center;
    /* Large/Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    margin-bottom: 0px;
}
.pieChartText > p {
    margin-bottom: 0px;
    color: #f8047e;
    text-align: center;

    /* H5/Bold */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 24px */
}
.anaylyticsChartHeader {
    padding: 16px;
    padding-bottom: 32px;
    border-bottom: 1px solid var(--Neutral-Gray-4);
    background-color: white;
    margin-top: 16px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.analyticChartBody {
    background-color: white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 328px;
    padding: 16px;
    overflow: hidden;
}
.yearLabel {
    color: var(--Neutral-Gray-6);
    /* Small/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    margin-bottom: 16px;
}

.yearText {
    color: var(--Brand-Secondary);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-bottom: 16px;
    cursor: pointer;
}
.blueFilterChip {
    color: var(--Neutral-White, #fff);

    /* Large/Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */

    border-radius: 20px;
    background: var(--Analytics-Analytics-1, #192bc2);
    padding: 5px 16px;
    cursor: pointer;

    display: flex;
    align-items: center;
}

.keywordCircle {
    background-color: #f8047e;
    height: 42px;
    width: 42px;
    border-radius: 100%;
    color: var(--Neutral-White, #fff);
    text-align: center;
    /* Small/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    display: flex;
    align-items: center;
    justify-content: center;
}
.tableRolloverItem {
    /* width: 20; */
    position: relative;
}
.tableRolloverItem > input {
    visibility: hidden;
    margin-left: -17px;
    /* margin-top: 1px; */
}

tbody .tableRow:hover {
    background: var(--secondary-5);
}
.tableRow:hover .tableRolloverItem > input {
    visibility: visible;
}
.tableRow:hover .tableRolloverItem > span {
    visibility: hidden;
}

.headerTableInput {
    margin-bottom: -3px;
    margin-right: 10px;
    margin-left: 4px;
    visibility: hidden;
}
.tableRow:hover .headerTableInput {
    visibility: visible;
}

/* input[type='checkbox']:checked::before {
    top: -2.5px;
} */
.rowCheckbox {
    position: relative;
    top: 3px;
    /* margin-left: -9px; */
}
.rowCheckbox:checked {
    /* top: 0px; */
    top: 3.4px;
}
.dragBody {
    border: 1px solid var(--Brand-Secondary);
}
.dragBG {
    position: absolute;
    border: 1px solid var(--Brand-Secondary);
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: var(--secondary-5);
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dropMessageBody {
    border-radius: 4px;
    background: var(--Brand-Secondary);
    padding: 4px 24px;
    width: 270px;
    display: flex;
    align-items: center;
    /* position: absolute;
    bottom: 0px;
    left: 39%; */
    animation: smooth-appear 1s ease forwards;
}
@keyframes smooth-appear {
    to {
        width: 273px;
        padding: 8px 24px;
    }
}
.dropMessageBody > div > p {
    color: white;

    /* Small/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    margin: 0px;
}
.dropMessageBody > div > h5 {
    color: white;
    /* Body/Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 16.8px */
    margin: 0px;
}

/* .container {
    background: gray;
    color:white;
    padding: 20px;
    position: fixed;
    bottom: -100%;
    opacity:0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    animation: smooth-appear 1s ease forwards;
  } */
.tableFirstRowItem {
    height: 49px;
    width: 100%;
    padding-top: 16.5px;
}
.dateText {
    color: var(--Brand-Primary);
    /* Small/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    margin-bottom: 0px;
}
.authorName {
    color: #b4007d;

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-bottom: 4px;
}
.arrowIconsChart {
    position: absolute;
    bottom: 19px;
    z-index: 1;
}
.selectYearLabel {
    color: var(--Neutral-Gray-6, #989bad);

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    width: 100px;
    width: 150px;
}
.yearsSelect {
    height: 32px;
    color: var(--Brand-Primary, #1b2149);

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    border-radius: 4px;
    border: 1px solid var(--Neutral-Gray-5);
    border-bottom: 1px solid var(--Neutral-Gray-6);
}
.circlesMainBody {
    padding: 16;
    background-color: white;
    width: 100%;
    /* height: 100%; */
    border-radius: 4px;
    margin-top: 24px;
    height: 504px;
    padding: 16px;
}
.fileCountChartBody {
    height: 185px;
    width: 40px;
    background: var(--Neutral-Gray-3);
    /* margin-bottom: 9px; */
    /* margin-bottom: -2px; */
    position: relative;
}
.fileCountLabel {
    color: var(--Neutral-Gray-6);

    /* Small/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    margin-left: -5px;
}
.fileCountBar {
    width: 40px;
    height: 0%;
    position: absolute;
    bottom: 0px;
    transition: height 0.5s ease;
}
.readBody {
    display: none;
    background-color: white;
    /* Elevation/Light/Shadow 04 */
    z-index: 1;
    position: absolute;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
}
.readBody > p {
    padding: 14px;
    min-width: 192px;
    border-bottom: 1px solid var(--Neutral-Gray-5);
    color: var(--Brand-Primary, #1b2149);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin: 0px;
}
.readByIcon:hover + .readBody {
    display: block !important;
}
.fileCountSection {
    margin-left: 32px;
}
.fileCountSection > span {
    color: var(--Neutral-Gray-6);

    /* Small/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
}
.fileCountSection > p {
    color: var(--Analytics-Analytics-1, #192bc2);

    /* Large/Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    margin-bottom: 16px;
}
.analyticsFilterTableBody {
    background-color: white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    /* height: 328px; */
    overflow: auto;
    height: calc(100vh - 165px);
}
.analyticsFilterTableBody > div {
    padding: 16px;
    padding-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.filterLabel {
    color: var(--Neutral-Gray-6);
    /* Small/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    margin-bottom: 0px;
}
.filterDesc {
    color: var(--Brand-Primary);
    /* Large/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    margin-bottom: 0px;
}
.backBtnText {
    color: var(--Brand-Secondary);

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    display: flex;
    align-items: center;
    cursor: pointer;
}
.bubbleTooltip > h5 {
    color: var(--Brand-Primary);

    /* Small/Bold */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 14.4px */
    margin-bottom: 0px;
}
.bubbleTooltip > p {
    color: var(--Brand-Primary);

    /* Small/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0px;
    line-height: 120%;
}
.searchInput {
    /* height: 48px; */
    border: 0px;
    outline: 0px;
    margin-left: 24px;
    width: 400px;

    /* H5/Regular */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 24px */
}
.wsMenuTrigger {
    color: var(--Brand-Primary, #1b2149);
    text-align: right;

    /* H5/Regular */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 24px */
    padding-left: 16px;
    border-left: 1px solid var(--Neutral-Gray-6, #989bad);
    display: flex;
    align-items: center;
    cursor: pointer;
}
