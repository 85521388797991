.viewBody {
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 24px;
    height: calc(100vh - 48px);
    background: var(--Neutral-Gray-3);
    overflow: auto;
}
.pageHeading {
    color: var(--Brand-Primary);
    /* H5/Bold */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin: 0px;
}
.subTitle {
    color: var(--Brand-Primary);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-bottom: 24px;
}
.menuText {
    color: var(--Brand-Secondary);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-bottom: 0px;
    margin-right: 32px;
    cursor: pointer;
}
.selectedMenu {
    color: var(--Brand-Primary);
    font-weight: 700;
    margin-right: 27px;
}
.whiteFullBody {
    background-color: white;
    border-radius: 4px;
    width: 100%;
    margin-top: 33px;
    height: calc(100vh - 187px);
    overflow: auto;
    padding: 16px 0px;
}
.cardHeader {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--Neutral-Gray-4);

    color: var(--Brand-Primary);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
}
.teamMemberBody {
    padding: 64px;
}
.inputLabel {
    color: var(--Brand-Primary);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-bottom: 5.5px;
}
.inputField {
    border-bottom: 1px solid var(--Neutral-Gray-6);
    height: 32px;
    border-radius: 4px;
}
.saveBtn {
    height: 32px;
    border-radius: 4px;
    background: var(--Brand-Secondary);
    padding: 5px 12px;
    color: white;

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    border: none;
}
.saveBtn:disabled {
    background-color: var(--Neutral-Gray-6);
}
.textBtn {
    color: var(--Brand-Secondary);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    cursor: pointer;
}
.teamMemberBody {
    padding: 24px 32px;
    border-bottom: 1px solid var(--Neutral-Gray-4);
}
.companyValuesBody {
    padding: 39px 32px;
}
.compValueHeader {
    color: var(--Brand-Primary);

    /* Large/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    margin-bottom: 48px;
}
.selectLabel {
    color: var(--Brand-Primary);

    /* Small/Bold */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 14.4px */
}
.selectDesc {
    color: var(--Brand-Primary);

    /* Large/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    margin-bottom: 32px;
}
.selectInput {
    border: none;
    width: 112px;
    height: 32px;
    border-bottom: 1px solid var(--Neutral-Gray-6);
    border-radius: 0px;
    margin-left: 48px;
    color: var(--Neutral-Gray-6);

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
}
.tableRow {
    border-bottom: 1px solid var(--Neutral-Gray-4);
}
.tableRow > td {
    padding: 16px;
    color: var(--Brand-Primary, #1b2149);

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
}
.tableKebab {
    visibility: hidden;
    color: var(--Neutral-Gray-6);
}
.tableRow:hover .tableKebab {
    visibility: visible;
}
.headerCell {
    font-weight: 700 !important;
}

.ragCircle {
    height: 23px;
    width: 23px;
    border: 1px solid var(--Neutral-Gray-4);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    background-color: white;
}
.ragCircleInside {
    height: 15px;
    width: 15px;
    background-color: var(--Neutral-Gray-4);
    border-radius: 100px;
}
.blueCard {
    border-radius: 4px;
    background: var(--Brand-Primary);
    padding: 24px 32px;
    margin-top: 24px;
}
.blueCardLabel {
    color: white;
    /* Large/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    /* margin-left: 16px; */
    margin-bottom: 0px;
}
.blueCardDesc {
    color: white;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */

    margin-bottom: 32px;
}
.chartMenuText {
    color: white;
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
}
.noCompanyValueText {
    color: var(--Neutral-Gray-6);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin: 32px;
}
