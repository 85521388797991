@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');
.viewHeight {
    background: var(--light);
    height: calc(100vh - 60px);
}

.whiteCard {
    width: 100%;
    background: white;
    padding: 24px 26px 24px 26px;
    height: 840px;
    overflow-y: auto;
    border-radius: 4px;
    box-shadow: 1px 2px 4px 0 rgba(130, 154, 177, 0.5);
}

.logoImg {
    width: 100%;
    object-fit: contain;
    height: 100px;
    margin-bottom: 20px;
}

.projectName {
    font-size: 20px;
    font-weight: bold;
    font-family: 'Open Sans';
    margin-bottom: 0px;
}
.blueNormal {
    font-size: 14px;
    font-family: 'Open Sans';
    color: var(--info);
}

.website {
    font-size: 14px;
    font-family: 'Open Sans';
    color: var(--Brand-Secondary);
    margin-bottom: 20px;
}

.dashedBody {
    border-top: 1px dashed rgba(130, 154, 177, 0.25);
    padding-top: 12px;
    padding-bottom: 12px;
}
.smallGray {
    font-size: 12px;
    color: var(--muted);
}

.blueTitle {
    font-size: 12px;
    color: var(--info);
    margin-bottom: 0px;
}
.detailTxt {
    font-size: 14px;
    font-family: 'Open Sans';
    font-weight: bold;
}
.paraText {
    font-size: 14px;
    line-height: 1.43;
}

.uploadBody {
    height: 100px;
    width: 200px;
    border: 1px solid #dce4eb;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    cursor: pointer;
    margin: auto;
    line-height: 0.2;
    margin-bottom: 20px;
}

.uploadText {
    margin-top: 10px;
    color: rgba(130, 154, 177, 0.5);
    font-size: 12px;
}

.whiteCardSmall {
    width: 100%;
    background: white;
    padding: 10px 24px;
    /* height: 28vh; */
    overflow-y: auto;
    border-radius: 4px;
    box-shadow: 1px 2px 4px 0 rgba(130, 154, 177, 0.5);
    margin-bottom: 16.5px;
}

.roundBadge {
    height: 28px;
    width: 28px;
    border-radius: 100px;
    background-color: var(--primary);
    font-size: 12px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.smallLabel {
    font-size: 12px;
    margin: 0px;
    margin-left: 10px;
}

.chartText {
    text-align: center;
    margin-top: -100px;
}

.chartBoldText {
    font-size: 24px;
    font-weight: bold;
    margin: 0px;
    margin-bottom: -0px;
}
.chatSubText {
    margin: 0px;
    font-size: 12px;
}

.grayText {
    color: var(--muted);
    font-size: 12px;
    line-height: 14px;
    margin-top: 20px;
}

.filesRow {
    height: 32px;
    border-bottom: 1px solid rgba(130, 154, 177, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rowItem {
    font-size: 12px;
    font-weight: normal;
    margin: 0px;
}

.linkChip {
    padding: 0px 12px;
    background-color: var(--muted);
    font-size: 14px;
    border-radius: 12px;
    color: white;
    margin-right: 8px;
    margin-bottom: 8px;
}
.dashedLine {
    border: dashed 1px rgba(130, 154, 177, 0.25);
    height: 1px solid;
    width: 100%;
}
.priorityBadge {
    height: 72px;
    width: 72px;
    border-radius: 100px;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-right: 10px;
    margin-bottom: 10px;
}
.swotBadge {
    height: 72px;
    width: 72px;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-right: 10px;
    margin-bottom: 10px;
    background-size: cover;
}
.linkText > a {
    color: var(--primary) !important;
}
.SBadge {
    background-image: url(/src/images/S.png);
}
.WBadge {
    background-image: url(/src/images/W.png);
}
.OBadge {
    background-image: url(/src/images/O.png);
}
.TBadge {
    background-image: url(/src/images/T.png);
}
