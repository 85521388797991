@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

.headerCont {
    width: 100%;
    background-color: var(--white);
    height: 60px;
    box-sizing: border-box;
    height: 60px;
    box-shadow: 0 1px 1px 0 rgba(130, 154, 177, 0.2);
    padding-left: 60px;
    padding-right: 30px;
    padding-top: 15px;
    position: relative;
    z-index: 10000;
}

.navContentBody {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headerContInner {
    width: 100%;
}

.headerSections {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

.rightPartInner {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}
.noNotification {
    margin: 13px;
    font-size: 16px;
    color: var(--muted);
}
.leftPart {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

.leftPartInner {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

.groupIconCont {
    cursor: pointer;
}
.link {
    margin: 0px 8px !important;
}

.link :hover {
    cursor: pointer;
}

.targetSector {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    /* margin-left: 30px; */
    align-items: center;
}

.targetNameTxt {
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primary);
    font-size: 18px;
    margin-right: 10px;
}

.targetSectorTxt {
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primary);
    font-size: 13px;
    margin-top: 3px;
}

.preDealTxt span {
    font-size: 10px;
    color: var(--info);
    margin-top: 4px;
}
.preDealTxtChip {
    font-size: 13px;
    color: white;
    margin-top: 4px;
    background-color: var(--info);
    border-radius: 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    width: 77px;
}

.otherTogglersInfo {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    grid-gap: 0px 15px;
    margin-right: 15px;
}

.profileImg {
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: var(--white);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profileImg img {
    width: 100%;
    height: auto;
}

.iconCont {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.notificationsCont {
    width: 300px;
    position: absolute;
    top: 41px;
    right: -16px;
    padding: 10px;
    background-color: var(--white);
    border-radius: 5px;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    /* border: 1px solid rgba(0, 0, 0, 0.15); */
    z-index: 4;
    padding-top: 0px;
    padding-bottom: 0px;
    overflow-y: auto;
    max-height: 400px;
    box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}

.notificationsCont ul {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
}

.notificationsCont ul li a {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    text-align: inherit;
    text-decoration: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    word-wrap: break-word;
    color: #829ab1;
    box-sizing: border-box;
}

.notificationsCont ul li a:hover {
    background-color: #e9ecef;
}

.overlay {
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    left: 0px;
    background-color: transparent;
}

.downIconCont img {
    width: 15px;
    height: 15px;
}

.blueText {
    color: var(--info);
}

.iconCont img {
    width: 24px;
    height: 24px;
}

.mainTargetSector {
    display: flex;
}
.mainTargetSector :hover {
    cursor: pointer;
}
.changeTargetContainer {
    position: absolute;
    margin-left: 2vw;
    top: 70px;
    z-index: 2;
    max-height: 550px;
}
.changeTargetContainer ul {
    list-style-type: none;
    overflow-y: scroll;
    max-height: 550px;
}
.changeTargetContainer ul li {
    padding: 8px 12px;
    height: 60px;
    width: 208px;
}

.changeTargetContainer ul li:hover {
    cursor: pointer;
}
.changeTargetContainer ul li:nth-of-type(odd) {
    background-color: white;
}
.changeTargetContainer ul li:nth-of-type(even) {
    background-color: #dce4eb;
}

.changetargetSector {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

.changetargetNameDesc {
    padding: 0px 5px;
}

.targetNameDesc {
    padding: 0px 20px;
    /* width: 252px; */
    display: flex;
    align-items: center;
}
.avatarStyle {
    height: 36px;
    width: 36px;
    border-radius: 100px;
    background-color: rgb(111, 105, 172);
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    font-size: 12px;
}
.avatarBody {
    margin-right: 8px;
    margin-left: 8px;
}
.notificationTitle {
    line-height: 14px;
    font-size: 12px;
    margin: 0px;
}
.notificationRow {
    /* border-bottom: 1px solid #d9d9d9; */
    /* padding-bottom: 10px; */
    padding-top: 5px;
    padding-right: 5px;
}
.notificationRow:hover {
    opacity: 0.8;
}
.notificationDate {
    color: var(--muted);
    line-height: 14px;
    font-size: 10px;
    margin: 0px;
    margin-top: 4px;
}
.notificationsTop {
    background-color: var(--primary);
    width: 100%;
    color: white;
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    /* margin-bottom: 10px; */
}
@media screen and (max-width: 768px) {
    .otherTogglersInfo {
        grid-gap: 0px 15px;
    }

    .targetSector {
        margin-left: 0px;
        width: 220px;
        margin-bottom: 30px;
        flex-flow: row !important;
        justify-content: flex-start;
    }

    .mainTargetSector {
        overflow-wrap: anywhere;
        padding: 0px 15px;
    }

    .targetNameTxt {
        color: var(--white);
        font-size: 12px;
    }

    .targetSectorTxt {
        color: var(--white);
        font-size: 14px;
    }

    .iconCont img {
        max-width: 30px;
    }

    .otherTogglersInfo {
        margin-right: 20px;
    }
}

.dropbtn {
    background-color: #04aa6d;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdownContent {
    display: none;
}

.dropdown:hover .dropdownContent {
    display: block;
}
.dropdown:hover .quickAddIcon {
    color: var(--primary);
}

.closeIcon {
    cursor: pointer;
    margin-right: 5px;
}

.menuIcon {
    position: absolute;
    left: 18px;
    top: 15px;
    cursor: pointer;
}

.menuTriangle {
    position: absolute;
    top: 14.6px;
    width: 32px;
    color: #c1c5c8;
    padding-top: 5px;
    z-index: 10;
}

.blueDot {
    background-color: var(--info);
    height: 8px;
    width: 8px;
    display: inline-block;
    border-radius: 100px;
    margin-left: 8px;
}
.viewAll {
    height: 14px;
    object-fit: contain;
    font-family: OpenSans;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    margin-top: 10px;
    cursor: pointer;
    color: var(--Brand-Secondary);
}

.addTargetText {
    text-align: end;
    font-size: 13px;
    color: var(--Brand-Secondary);
    padding-right: 28px;
    padding-top: 14px;
    padding-bottom: 14px;
    cursor: pointer;
}
.targetCell {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.cellTargetName {
    font-weight: bold;
    font-family: 'Open Sans';
    font-size: 16px;
    margin: 0;
    margin-right: 10px;
}
.descCell {
    margin: 0;
    font-size: 13px;
    /* margin-top: 5px; */
}

.targetCell:hover {
    background-color: var(--light);
}

.triangleBottom {
    width: 15px;
    height: 1px;
    background: red;
    z-index: 10;
    position: absolute;
    top: 21px;
    left: 3px;
    background: #f3f3f3;
}

.logoutMenu {
    width: 55px;
    left: -10px;
    padding-left: 15px;
}

.quickAddIcon {
    color: var(--Brand-Secondary);
}

.projectName {
    font-size: 16px;
    font-weight: bold;
    font-family: 'OpenSans';
    margin-bottom: 0px;
}
