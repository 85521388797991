:root {
    --primary: #003149;
    --success: #00c851;
    --danger: #f00;
    --warning: #fb3;
    --info: #33b5e5;
    --dark: #023;
    --muted: #829ab1;
    --side-sub-menu-clicked: #366c8e;
    --side-menu-clicked: #275877;
    --side-menu-rollover: #184561;
    --aa-0-side-menu: #447aa0;
    --white: #fff;
    --primary-font-family: 'Inter';
    --investor: #6f69ac;
    --consultant: #003149;
    --management: #ac69ac;
    --Brand-Primary: #1b2149;
    --Brand-Secondary: #8e2de2;
    --Neutral-Gray-2: #f6f6f8;
    --Neutral-Gray-3: #f1f2f4;
    --Neutral-Gray-4: #dddee4;
    --Neutral-Gray-5: #c6c8d2;
    --Neutral-Gray-6: #989bad;
    --Neutral-Background-Transparent-Rest: rgba(255, 255, 255, 0);
    --RAG-Red: #b00020;
    --RAG-Amber: #ffc107;
    --RAG-Green: #198754;
    --secondary-5: rgba(142, 45, 226, 0.05);
    /* --checkbox-color: #003149; */
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
    margin: 0;
    font-family: var(--primary-font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
button {
    font-family: var(--primary-font-family);
}
button:disabled {
    background-color: var(--Neutral-Gray-5) !important;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.center {
    margin: auto;
    width: 50%;
    max-width: 400px;
    min-width: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    text-align: center;
}

div.loader {
    font-size: 100px;
    color: var(--primary);
}

.loaded {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
}

.full-height-cls {
    height: 100vh !important;
}

/* Editor Css */

.ql-container.ql-editor {
    height: 100% !important;
}

.ql-container {
    background-color: white !important;
}

.ql-snow {
    border-top: 1px solid #ccc !important;
}

.ql-toolbar {
    height: 32px !important;
    align-items: center;
    /* display: flex; */
    text-align: end !important;
    border: none !important;
}

.quill {
    display: flex;
    flex-direction: column;
    /* background: white; */
    /* border-top: 1px solid #dee0e2; */
}
.ql-editor {
    padding: 6px 10px !important;
}
.ql-toolbar .ql-icon {
    color: red !important; /* Change color */
    font-size: 10px !important; /* Change size */
}

.ql-editor.ql-blank::before {
    font-style: normal !important;
    font-size: 16px !important;
    color: var(--muted) !important;
}

.ql-formats {
    margin-left: 10px;
    margin-right: 0px !important;
}
.ql-stroke {
    stroke: var(--Neutral-Gray-6) !important;
}
.ql-fill {
    fill: var(--Neutral-Gray-6) !important;
}
/* //PDF VIEW */
.DocumentContainer {
    background: white !important;
}

/*
 *  STYLE 1
 */

/* ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #666666;
} */

.select__multi-value {
    object-fit: contain;
    border-radius: 5px !important;
    background-color: var(--light) !important;
}

.select__menu {
    /* width: 400px !important; */
    margin-left: 1px !important;
    z-index: 100;

    /* overflow-y: auto; */
    /* overflow-y: hidden !important; */
}

.select__menu-list {
    /* overflow: hidden !important; */
    max-height: 250px !important;
}
.select__placeholder {
    color: var(--Neutral-Gray-6) !important;
}

iframe {
    /* height: 70vh !important; */
    height: calc(100vh - 250px);
}

/* //DatePicker */
.react-datepicker__navigation {
    top: 9px !important;
}

.react-datepicker__year-read-view--down-arrow {
    top: 3px !important;
}

.quilLabel {
    margin-bottom: -39px !important;
    margin-top: 7px !important;
}

.input-group-text {
    background-color: #ff000000 !important;
}

.privacy-link {
    color: var(--muted) !important;
}

.font-weight-bold {
    font-weight: bold;
}

.smallFont {
    font-size: 12px !important;
}

.regularFont {
    font-size: 14px !important;
}

.smallButton {
    font-size: 12px !important;
    padding: 2px 20px !important;
}

.Toastify__toast-container {
    width: 562px !important;
}

/* Scroll Setting */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: white;
    /* or add it to the track */
}

/* Add a thumb */
::-webkit-scrollbar-thumb {
    background: var(--Neutral-Gray-6);
}

/* Loader CSS */
@keyframes rotatingLoader {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.rotateLoader {
    animation: rotatingLoader infinite 1s linear;
}

.offcanvas-end {
    /* padding-top: 60px; */
}
.offcanvas-backdrop {
    z-index: 1000000 !important;
}
.offcanvas-end {
    z-index: 10000000 !important;
}

/* Custom Checkbox Styling / */

/* Remove appearance styles to ensure consistent appearance across browsers */
input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 15px;
    width: 15px;
    border: 1px solid #666666;
    border-radius: 3px;
}

/* Define the custom checkbox appearance */
input[type='checkbox']:checked {
    background-color: #666666;
    position: relative;
}

/* Use content to display the tick icon */
input[type='checkbox']:checked::before {
    content: '\f00c';
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    font-size: 9px; /* Adjust size as needed */
    color: white;
    position: absolute; /* Position the tick icon absolutely */
    top: 50%; /* Move the tick icon vertically to the center */
    left: 50%; /* Move the tick icon horizontally to the center */
    transform: translate(-50%, -50%); /* Center the tick icon */
}
/* Reduce opacity for disabled checkboxes */
input[type='checkbox']:disabled {
    opacity: 0.5;
}

.apexcharts-menu-item.exportSVG,
.apexcharts-menu-item.exportPNG {
    display: none;
}
/* reacr donut chart */
.donutchart-arcs-path {
    opacity: 1 !important;
}

/* Date picker custom dropdown */
.date-picker-dropdown > div > div {
    transform: none !important;
    top: 38px !important;
}

.react-calendar__month-view__days__day {
    color: #003149 !important;
}

.react-calendar__tile--active {
    background-color: #33b5e5 !important;
}

.react-calendar__month-view__days__day--weekend {
    color: var(--Brand-Secondary) !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #829ab1 !important;
}

.btn:disabled {
    border-color: #829ab1 !important;
    /* opacity: 0.7 !important; */
    color: white !important;
}
/* CSS */
::-webkit-calendar-picker-indicator {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M7 11C7.55228 11 8 10.5523 8 10C8 9.44771 7.55228 9 7 9C6.44772 9 6 9.44771 6 10C6 10.5523 6.44772 11 7 11ZM8 13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13C6 12.4477 6.44772 12 7 12C7.55228 12 8 12.4477 8 13ZM10 11C10.5523 11 11 10.5523 11 10C11 9.44771 10.5523 9 10 9C9.44771 9 9 9.44771 9 10C9 10.5523 9.44771 11 10 11ZM11 13C11 13.5523 10.5523 14 10 14C9.44771 14 9 13.5523 9 13C9 12.4477 9.44771 12 10 12C10.5523 12 11 12.4477 11 13ZM13 11C13.5523 11 14 10.5523 14 10C14 9.44771 13.5523 9 13 9C12.4477 9 12 9.44771 12 10C12 10.5523 12.4477 11 13 11ZM17 5.5C17 4.11929 15.8807 3 14.5 3H5.5C4.11929 3 3 4.11929 3 5.5V14.5C3 15.8807 4.11929 17 5.5 17H14.5C15.8807 17 17 15.8807 17 14.5V5.5ZM4 7H16V14.5C16 15.3284 15.3284 16 14.5 16H5.5C4.67157 16 4 15.3284 4 14.5V7ZM5.5 4H14.5C15.3284 4 16 4.67157 16 5.5V6H4V5.5C4 4.67157 4.67157 4 5.5 4Z" fill="%23989BAD"/></svg>');
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
