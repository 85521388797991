.sidebarBody {
    height: calc(100vh - 48px);
    background: var(--Neutral-Gray-3);
    padding-top: 64px;
    transition: width 0.2s;
}

/* Menu Container */
.menuBody {
    padding: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
/* .menuLabel {
    display: inline;
    transition: display 0.2s;
} */

.menuLabel {
    animation: fadeIn 0.2s ease-in-out; /* Adjust the duration and timing function as needed */
    opacity: 1; /* Start with opacity set to 0 */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: var(--brand-primary, #1b2149);
    margin-left: 18px;
    width: 120px;
    overflow: hidden;
}
/*  */

.currentScreenText {
    /* font-family: Inter; */
    /* font-size: 14px; */
    /* font-weight: 900; */
    margin-left: 4px;
}
