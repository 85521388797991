.viewBody {
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 24px;
    height: calc(100vh - 48px);
    background: var(--Neutral-Gray-3);
    overflow: auto;
}
.pageHeading {
    color: var(--Brand-Primary);
    /* H5/Bold */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin: 0px;
}
.subTitle {
    color: var(--Brand-Primary);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-bottom: 24px;
}
.headerSimpleButton {
    font-family: Inter;
    border-radius: 4px;
    border: 1px solid var(--Brand-Secondary);
    color: var(--Brand-Secondary);
    background: var(--Neutral-Background-Transparent-Rest);
    width: 200px;
    font-size: 14px;
    margin-right: 24px;
    height: 32px;
    margin-bottom: 32px;
}
.selectedBtn {
    background: var(--Brand-Secondary);
    color: white;
}
.maturityIndexBody {
    background-color: white;
    width: 100%;
}
.tableRow {
    border-bottom: 1px solid var(--Neutral-Gray-5);
}
.headerItem {
    padding: 16px;
    color: var(--Brand-Primary);
    /* Large/Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    min-width: 230px;
}
.statusHeading {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
}
.tableItem {
    padding: 16px;
    color: var(--Brand-Primary);
    /* Large/Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
}
.tableItem > p {
    margin-bottom: 2px;
}
.tableItem > span {
    font-size: 12px;
}
.maturityScoreBoard {
    border-radius: 4px;
    background: var(--Brand-Primary);
    width: 100%;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 32px;
    padding-top: 16px;
    color: white;
    /* H5/Regular */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 24px */
    text-align: center;
}
.roundCirlcle {
    width: 160px;
    height: 160px;
    border-radius: 100%;
    border: 10px solid green;
    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    /* H1/Bold */
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 38.4px */
    margin: auto;
    margin-top: 24px;
}
.sliderThumb {
    height: 12px;
    width: 12px;
    border-radius: 100%;
    background-color: red;
}
.sliderRail {
    background-color: var(--Neutral-Gray-5);
    width: 100%;
    height: 5px;
    border-radius: 10px;
}
.sliderMenu {
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0);
    /* Elevation/Light/Shadow 08 */
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.14);
    position: absolute;
    width: 100%;
    text-align: left;
    padding: 5px 5px;
    background-color: white;
    z-index: 10000;
    margin-top: 10px;
}

.sliderMenu > h6 {
    color: var(--Brand-Primary);
    /* Small/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 0px;
}
.sliderMenu > p {
    color: var(--Brand-Primary);
    /* Small/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin: 0px;
}
.showInfoMenu {
    cursor: pointer;
}
.infoMenu {
    display: none;
    border: 1px solid rgba(255, 255, 255, 0);
    width: 240px;
    /* height: 268px; */
    background-color: white;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.14);
    position: absolute;
    top: 22px;
    border-radius: 4px;
    z-index: 100000000000;
    left: 0;
    padding: 8px 12px;
    padding-bottom: 0px;
}

.showInfoMenu:hover .infoMenu {
    display: block !important;
}
.infoMenuItem {
    margin-bottom: 15px;
}
.infoMenuItem > p {
    margin: 0px;
    color: var(--Brand-Primary);
    /* Small/Bold */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 14.4px */
}
.infoMenuItem > span {
    color: var(--Brand-Primary);
    /* Small/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}
