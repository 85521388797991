.smallFont {
    font-size: 12px !important;
}

.smallCheckBox {
    height: 16px !important;
    width: 16px !important;
}

.checkInput:checked + .smallCheckBox:before {
    height: 6px !important ;
    width: 6px !important ;
}
.checkInput:checked + .smallCheckBox:before {
    height: 6px !important ;
    width: 6px !important;
}

.labelAddQuestion {
    font-size: 12px;
}

.smallButton {
    height: 24px;
    font-size: 12px;
    padding-top: 2px;
    padding-left: 16px;
    padding-right: 16px;
}
.notesMainBody {
    height: calc(100vh - 100px);
    overflow: auto;
}
.titleInput {
    border: none;

    margin-top: -1px;
    color: var(--Brand-Primary);

    /* Large/Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
}

.titleInput:focus {
    outline: none;
}
.blueText {
    font-size: 10px;
    color: var(--info);
}
.richTextDiv {
    padding: 16px;
    border-radius: 4px;
    background: var(--Neutral-Gray-2);
    /* max-height: 66px;  */
    /* Height for two lines of text */
    margin-top: 8px;
    margin-bottom: 7px;
}

.richText {
    /* display: -webkit-box;  */
    /* Use the flexible box layout model */

    /* -webkit-line-clamp: 2;  */
    /* Limit the number of lines to 4 */
    /* -webkit-box-orient: vertical; */
    /* Set the box orientation to vertical */
    /* overflow: hidden; */
    /* Hide any overflow */

    margin: 0px;
    color: var(--Brand-Primary);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}
.richText > p {
    margin: 0px;
    /* display: -webkit-box; */
    /* Use the flexible box layout model */

    /* -webkit-line-clamp: 2;  */
    /* Limit the number of lines to 4 */
    /* -webkit-box-orient: vertical; */
    /* Set the box orientation to vertical */
    /* overflow: hidden; */
    /* Hide any overflow */
}

.customQuill > div:nth-child(2) {
    border: none;
    border-top-color: white !important;
}
.customQuill > div:nth-child(2) > div {
    /* padding: 0px !important; */
    padding: 0px;
    border-top: none !important;
    border-color: white;
}

.customMenu {
    background-color: white;
    width: 240px;
    /* Elevation/Light/Shadow 04 */
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
}
.customMenu > p {
    padding: 14px;
    color: var(--Brand-Primary);

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    cursor: pointer;
    margin-bottom: 0px;
    border-bottom: 1px solid var(--Neutral-Gray-5);
}
.customMenu > p:hover {
    background-color: #e9ecef;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.hoverMenuContent {
    display: none;
}

.dropdown:hover .hoverMenuContent {
    display: block;
}
.hoverMenuCont {
    width: 175px;
    position: absolute;
    right: -0px;
    /* min-width: 10rem; */
    text-align: left;
    color: #212529;
    z-index: 4;
    overflow-y: auto;
}
.editSave {
    color: var(--Brand-Secondary, #8e2de2);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    cursor: pointer;
    margin-top: 8px;
}

.fieldLabel {
    color: var(--Brand-Primary);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-bottom: 5.5px;
}

.customReactQuill > div > div:nth-child(2) {
    border: 1px solid var(--Neutral-Gray-5) !important;
    border-bottom: 1px solid var(--Neutral-Gray-6) !important;
    border-radius: 4px;
}

.customReactQuill > div > div:nth-child(2) {
    border: 1px solid var(--Neutral-Gray-5) !important;
    border-bottom: 1px solid var(--Neutral-Gray-6) !important;
    border-radius: 4px;
}
.saveButton {
    width: 87px;
    height: 32px;
    background-color: var(--Brand-Secondary);
    border: none;
    margin-top: 40px;
}

.dateText {
    color: var(--Neutral-Gray-6, #989bad);

    /* Small/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
}
.paginationBody > li {
    color: red;
    padding: 8px;
    color: var(--Neutral-Gray-6, #989bad);

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
}
.paginationBody > li:first-child {
    display: none;
}
.paginationBody > li:last-child {
    display: none;
}
.paginationBody > li:first-child {
    display: none;
}

.paginationBody > li > a {
    border: none;
}
.activePage > a {
    background-color: white !important;
    color: var(--Brand-Primary) !important;
    box-shadow: none !important;
    border: none !important;
    font-weight: 700 !important;
}
.pageItem {
    padding: 0px !important;
}
.pageItem > a {
    padding: 12px;
    color: var(--Neutral-Gray-6);
}
.paginatinoMainBody {
    width: 290px;
    overflow: hidden;
    margin-right: 5px;
}
.paginationIcon {
    margin-top: -14px;
}
