.viewBody {
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 24px;
    height: calc(100vh - 48px);
    background: var(--Neutral-Gray-3);
}
.pageHeading {
    color: var(--Brand-Primary);
    /* H5/Bold */
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin: 0px;
}
.pageSubHeading {
    color: var(--Brand-Primary);

    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
}
.infoReadtText {
    color: var(--Brand-Primary);
    /* Body/Regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
}
.headerSimpleButton {
    font-family: Inter;
    border-radius: 4px;
    border: 1px solid var(--Brand-Secondary);
    color: var(--Brand-Secondary);
    background: var(--Neutral-Background-Transparent-Rest);
    width: 200px;
    font-size: 14px;
    margin-right: 24px;
    height: 32px;
}
.selectedBtn {
    background: var(--Brand-Secondary);
    color: white;
}
.whiteCard {
    width: 100%;
    background-color: white;
    border-radius: 4px;
    height: calc(100vh - 200px);
    overflow: auto;
}
.cardTitle {
    font-family: Inter;
    color: var(--Brand-Primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    padding: 16px;
    padding-bottom: 24px;
    display: block;
    margin: 0px;
}
.checkListItem {
    font-family: Inter;
    padding: 16px;
    color: var(--Brand-Primary);
    font-size: 14px;
    border-top: 1px solid var(--Neutral-Gray-3);
    margin: 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.fileCount {
    color: var(--Neutral-Gray-6);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.fileCardBody {
    padding-left: 16px;
    padding-right: 16px;
}

.infoExample {
    color: var(--Neutral-Gray-6);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-top: 16px;
    margin-bottom: 8px;
}
.documentExamples {
    color: var(--Brand-Primary);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    padding-left: 20px;
}
.dragDropBody {
    border-radius: 4px;
    border: 1px dashed var(--Neutral-Gray-6);
    background: var(--Neutral-Gray-2);
    width: 100%;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--Brand-Secondary);
    /* Body/Bold */
    font-family: Inter;
    font-size: 14px;
    margin-top: 40px;
    margin-bottom: 24px;
    text-align: center;
}
.fieldLabel {
    color: var(--Brand-Primary);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 6px;
}
.inputField {
    width: 100%;
    margin-bottom: 24px;
}

.saveBtn {
    border-radius: 4px;
    background: var(--Brand-Secondary);
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    color: white;
    border: none;
    height: 32px;
    width: 64px;
    margin-top: 8px;
    margin-bottom: 40px;
}

.uploadFilesTitle {
    color: var(--Neutral-Gray-6);
    /* Small/Bold */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 14.4px */
}
.fileBody {
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--Neutral-Gray-3);
}
.fileTitle {
    color: var(--Brand-Primary);
    /* Body/Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 16.8px */
    margin-bottom: 2px;
}
.fileSubtext {
    color: var(--Neutral-Gray-6);
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 2px;
    line-height: 120%; /* 13.2px */
}
.deleteIcon {
    display: none !important;
    cursor: pointer;
}

.fileBody:hover .deleteIcon {
    display: block !important;
}

.fileListItem {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0px;
    /* color: var(--Neutral-Gray-7); */
    border-bottom: 1px solid var(--Neutral-Gray-5);
}
.noFolderSelected {
    color: var(--Neutral-Gray-6);
    /* Body/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin: 16px;
}
