.webAppInner {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content 1fr;
    position: fixed;
    height: 100%;
    width: 100%;
}

.headerCont {
    grid-row: 1/2;
    grid-column: 2/3;
    width: auto;
}

.leftSidebarCont {
    grid-row: 1/3;
    grid-column: 1/2;
    width: max-content;
    height: 100%;
}

.webBody {
    height: 100%;
    overflow: auto;
    background-color: var(--light);
}

.webBodyInner {
    height: 100%;
}

.viewHeight {
    height: calc(100vh - 48px);
    background: var(--Neutral-Gray-3);
    overflow-y: auto;
}
