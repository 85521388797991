.listBody {
    background: var(--light);
    padding: 30px;
    height: 90vh;
    overflow-y: auto;
}

.fieldIconBody {
    background-color: white;
}
.fieldIconBody > span {
    color: #a8a6a6;
}
.title {
    font-size: 24px;
    color: var(--primary);
    margin-bottom: 30px;
}

.listSection {
    background-color: white;
    width: 100%;
    height: 80px;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 2px 3px 6px #b6b6b6;
    margin-top: 2.5px;
    cursor: pointer;
}
.listSectionSimple {
    width: 100%;
    height: 80px;
    padding: 20px;
    border-radius: 4px;
    margin-top: 2.5px;
    cursor: pointer;
}

.avatarStyle {
    height: 40px;
    width: 40px;
    border-radius: 100px;
    background-color: rgb(111, 105, 172);
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.msgTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.44;
    font-family: OpenSans;
    line-height: 1.44;
    margin: 0px;
}
.msgDescription {
    font-family: OpenSans;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: var(--muted);
    line-height: 1.54;
    height: 20px;
}

.msgTime {
    font-size: 16px;
    color: var(--muted);
    margin-bottom: -3px;
}
.notificationBadge {
    background-color: var(--Brand-Secondary);
}
.starIcon {
    color: var(--muted);
}
.starIconSelected {
    color: var(--Brand-Secondary);
}

@media only screen and (max-width: 1260px) and (min-width: 989px) {
    .listSection,
    .listSectionSimple {
        min-width: 330px;
    }
}
